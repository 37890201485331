import React, { useContext, useState, useEffect } from "react";
import {
  Container,
  Menu,
  Segment,
  Dropdown,
} from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { Link, NavLink } from "react-router-dom";
import { RootStoreContext } from "../../app/stores/rootStore";
import { AuthenticationState } from "react-aad-msal";
import AboutForm from "../modals/AboutForm";
import ToolOptions from "../admintools/ToolOptions";

interface IProps {
  login: () => void;
  logout: () => void;
  authenticationState: AuthenticationState;
}

const NavBar: React.FC<IProps> = ({ login, logout, authenticationState }) => {
  const rootStore = useContext(RootStoreContext);
  const { openModal } = rootStore.modalStore;
  const { adminAccess, initiationAdminAccess } = rootStore.initiationStore;

  const isInProgress = authenticationState === AuthenticationState.InProgress;
  const isAuthenticated =
    authenticationState === AuthenticationState.Authenticated;
  const isUnauthenticated =
    authenticationState === AuthenticationState.Unauthenticated;

  const [width, setWidth] = useState(window.innerWidth);
  const { accountInfoUserName, accountInfoUserEmail } = rootStore.userStore;

  useEffect(() => {
    //const handleResize = _debounce(() => setWidth(window.innerWidth), 100)
    const handleResize = () => setWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isAdmin = () => {

    const admin = adminAccess.filter(a => a.userEmail === accountInfoUserEmail);

    if (admin.length > 0)
      return true;

    return false;
  };

  return (
    <Segment inverted>

      {/* {console.log("width is :" + width)} */}

      <Menu fixed="top" inverted size="large" className="noPrint" >
        <Container>

          {width < 768 ? (
            <>
              <Menu.Item header as={NavLink} exact to="/" className='cedLogo'>
                <img
                  src="/assets/CED_Horizontal Logo_WHITE_RBG_small.png"
                  alt="logo"
                  style={{ height: "100%", width: "100%" }}
                />
              </Menu.Item>

              <Menu.Menu position="right">
                <Menu.Item >
                  <Dropdown className="icon" icon="sidebar" pointing item >
                    <Dropdown.Menu>
                      {(isUnauthenticated || isInProgress) && (
                        <Dropdown.Item text="Login" onClick={login} />
                      )}
                      {isAuthenticated && (
                        <Dropdown.Item text="Logout" onClick={logout} />
                      )}

                      <Dropdown.Item text="Home" as={Link} to="/" />
                      {(isAdmin()) &&
                      <Dropdown item text="Tools">
                        <Dropdown.Menu>
                          <Dropdown.Item href={"tools/AddMunicipality"}>Add Municipality</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      }
                      <Dropdown.Item
                        text="About"
                        onClick={() => openModal(<AboutForm />)}
                      />
                    </Dropdown.Menu>
                  </Dropdown>
                </Menu.Item>
              </Menu.Menu>
            </>
          ) : (
            <>
              <Menu.Item header as={NavLink} exact to="/" className='cedLogo'>
                <img
                  src="/assets/CED_Horizontal Logo_WHITE_RBG_small.png"
                  alt="logo"
                  style={{ height: "100%", width: "100%" }}
                />
              </Menu.Item>

              <Menu.Menu position="right">
                {(isUnauthenticated || isInProgress) && (
                  <Menu.Item name="Login" onClick={login} />
                )}
                {isAuthenticated && (
                  <Menu.Item name="Logout" onClick={logout} />
                )}
                <Menu.Item name="Home" as={Link} to="/" />
                {(isAdmin()) &&
                  <Dropdown item text="Tools">
                    <Dropdown.Menu>
                      <Dropdown.Item href={"tools/AddMunicipality"}>Add Municipality</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                }
                <Menu.Item name="About" onClick={() => openModal(<AboutForm />)} />

              </Menu.Menu>
            </>
          )}

        </Container>
      </Menu>
    </Segment>
  );
};

export default observer(NavBar);
