export interface IConflictResponse {
    id: number;
    history: boolean;
    relationship: boolean;
    description: string;
    conflict: boolean;
    createdByName: string;
    createdByEmail: string;
    createDate: Date;
}

export class IConflictResponseFormValues implements IConflictResponse {
    id: number = 0;
    history: boolean = false;
    relationship: boolean = false;
    description: string = '';
    conflict: boolean = false;
    createdByName: string = '';
    createdByEmail: string = '';
    createDate: Date = new Date();

    constructor(init?: IConflictResponseFormValues) {
        Object.assign(this, init);
    }
}