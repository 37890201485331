import { observable, action, runInAction, computed } from "mobx";
import { toast } from "react-toastify";
import { history } from "../..";
import { RootStore } from "./rootStore";
import agent from "../api/agent";

//import { IConflict } from "../models/conflict";
import { IInitiation } from "../models/initiation";

import { IAdmin } from "./../models/admin";
import { IManager } from "../models/manager";

import { IMunicipality } from "./../models/municipality";
import { IServerFolder } from "./../models/serverFolder";
import { IProjectTemplate } from "./../models/projectTemplate";
import { ISuffix } from "./../models/suffix";
import { IInitiationCompany } from "./../models/initiationCompany";
import { IClient } from "./../models/client";
import { IProject } from "../models/project";
import { IAdminAccess } from "../models/adminAccess";

//import { IState } from "../models/state";
//import { IClientType } from "../models/clientType";

const PAGE_SIZE = 10;

export default class InitiationStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable initiation: IInitiation | null = null;

  //@observable conflictRegistry = new Map<number, IConflict>();
  @observable initiationRegistry = new Map();

  @observable submitting = false;
  @observable loadingInitial = false;
  @observable iCompanies: IInitiationCompany[] = [];

  @observable suffixes: ISuffix[] = [];
  @observable municipalSuffixes: ISuffix[] = [];
  @observable projectSuffixes: ISuffix[] = [];
  @observable existingProjectSuffixes: ISuffix[] = [];

  @observable bClients: IClient[] = [];
  @observable bProjectTemplates: IProjectTemplate[] = [];
  @observable bServerFolders: IServerFolder[] = [];

  @observable municipalities: IMunicipality[] = [];
  @observable admins: IAdmin[] = [];

  @observable adminAccess: IAdminAccess[] = [];

  @observable managers: IManager[] = [];
  //@observable clientTypes: IClientType[] = [];
  //@observable states: IState[] = [];

  @observable projects: IProject[] = [];

  @observable responseFormVisible: boolean = false;

  @observable initiationCount: number = 0;
  @observable page = 0;

  @observable predicate = new Map<string, string | number | boolean>();

  /*************************************
   *
   *************************************/

  @action setCount = (count: number) => {
    this.initiationCount = count;
  };

  /*************************************
   *  needed ?
   *************************************/

  @action setResponseFormVisible = (value: boolean) => {
    this.responseFormVisible = value;
  };

  /*************************************
   *
   *************************************/

  @computed get totalPages() {
    //console.log("count: " + this.conflictCount);
    //console.log("PAGE_SIZE: " + PAGE_SIZE);

    return Math.ceil(this.initiationCount / PAGE_SIZE);
  }

  /*************************************
   *
   *************************************/

  @action setPage = (page: number) => {
    this.page = page;
  };

  /*************************************
   *
   *************************************/

  get initiationsLength() {
    return this.initiationRegistry.size;
  }

  /*************************************
   *
   *************************************/

  @action setPredicate = (
    predicate: string,
    value: string | number | boolean
  ) => {
    if (predicate !== "all") {
      this.predicate.set(predicate, value);
    }
  };

  /*************************************
   *
   *************************************/

  @computed get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", `${this.page}`);
    params.append("pageSize", String(PAGE_SIZE));

    this.predicate.forEach((value: string | number | boolean, key: string) => {
      params.append(key, value.toString());
    });

    return params;
  }

  @action clearPredicate = () => {
    this.predicate.clear();
  };

  @action removePredicate = (key: string) => {
    this.predicate.delete(key);
  };

  @action clearInitiationRegistry = () => {
    // console.log(this.initiationRegistry);
    this.initiationRegistry.clear();
    this.page = 0;
  };


  /*************************************
   *
   *************************************/

   @action initiationAdminAccess = async () => {
    try {
      const adminAccess = await agent.Initiation.adminAccess();

      runInAction("loading users with admin access", () => {
        this.adminAccess = adminAccess;
      });
    } catch (error) {
      runInAction("load users with admin access error", () => {
        this.loadingInitial = false;
      });
      console.log(error);
    }
  };



  /*************************************
   *
   *************************************/

  @action findProject = async (searchVal: string) => {
    try {
      const projects = await agent.Initiation.searchProjects(searchVal);

      runInAction("loading projects", () => {
        this.projects = projects;
        //this.projectRegistry.clear();
        //projects.forEach((project) => {
        //    this.projectRegistry.set(project.title, project);
        //});
      });
    } catch (error) {
      runInAction("load projects error", () => {
        this.loadingInitial = false;
      });
      console.log(error);
    }
  };

  @action serverFolderClients = async (serverFolder: number) => {
    try {
      let clients = await agent.Initiation.bServerClients(serverFolder);
      //Create "Add Client" menu option and put it at the start of the clients list
      const addPerson: IClient = {id: 0, clientName: "Add Client"}
      clients.unshift(addPerson);

      runInAction("loading server folder clients", () => {
        this.bClients = clients;
        //  this.taskRegistry.clear();
        //  tasks.forEach((task) => {
        //      this.taskRegistry.set(task.taskCode, task);
        //  });
      });
    } catch (error) {
      runInAction("loading server folder clients error", () => {
        this.loadingInitial = false;
      });
      console.log(error);
    }
  };

  @computed get initiationsByDefault() {
    return Array.from(this.initiationRegistry.values()).sort(
      (a, b) =>
        Date.parse(b.createDate.toString()) -
        Date.parse(a.createDate.toString())
    ); // sort in descending order
  }

  /*************************************
   *
   *************************************/

  @computed get initiationsByDate() {
    return this.groupInitiationsByDate(
      Array.from(this.initiationRegistry.values())
    );
  }

  /*************************************
   *
   *************************************/

  groupInitiationsByDate(initiations: IInitiation[]) {
    const sortedInitiations = initiations.sort(
      (a, b) =>
        Date.parse(b.createDate.toString()) -
        Date.parse(a.createDate.toString())
    );

    return Object.entries(
      sortedInitiations.reduce((initiations, initiation) => {
        const date = new Date(initiation.createDate.toString()).toDateString();
        initiations[date] = initiations[date]
          ? [...initiations[date], initiation]
          : [initiation];
        return initiations;
      }, {} as { [key: string]: IInitiation[] })
    );
  }

  /*************************************
   *
   *************************************/

  @computed get initiationsByManager() {
    // console.log('registry.values: ' + this.vehicleAccidentReportRegistry.values());
    return this.groupInitiationsByManager(
      Array.from(this.initiationRegistry.values())
    );
  }

  /*************************************
   *
   *************************************/

  groupInitiationsByManager(initiations: IInitiation[]) {
    return Object.entries(
      initiations.reduce((initiations, initiation) => {
        const name = initiation.projectManagerName;
        initiations[name] = initiations[name]
          ? [...initiations[name], initiation]
          : [initiation];
        return initiations;
      }, {} as { [key: string]: IInitiation[] })
    );
  }

  /*************************************
   *
   *************************************/

  @action loadNewInitiationInfo = async () => {
    console.log("loading new initiation info...");
    this.loadingInitial = true;

    try {
      const response = await agent.Initiation.newInitiationInfo();
      // console.log(response);

      runInAction("loading new initiation info", () => {
        this.managers = response.managersToReturn;
        this.admins = response.admins;

        this.iCompanies = response.initiationCompanies;

        this.suffixes = response.suffixes;

        // Order is: Proposal, Project, Government, None

        this.municipalSuffixes = Object.assign([], this.suffixes);
        this.municipalSuffixes.splice(1, 1); // remove project
        this.municipalSuffixes.splice(1, 1); // remove government

        this.projectSuffixes = Object.assign([], this.suffixes);
        this.projectSuffixes.splice(3, 1); // remove none

        this.existingProjectSuffixes = Object.assign([], this.suffixes);
        this.existingProjectSuffixes.splice(0, 1); // remove proposal
        this.existingProjectSuffixes.splice(2, 1); // remove none


        this.bProjectTemplates = response.projectTemplates;
        this.bServerFolders = response.serverFolders;
        //this.bServerFolders.splice(13,1); //Test remove marketing folder.
        


        this.municipalities = response.municipalities;

        //this.states = response.states;
        //this.clientTypes = response.clientTypes;

        this.loadingInitial = false;
      });
    } catch (error) {
      runInAction("load new initiation info error", () => {
        this.loadingInitial = false;
      });
      console.error(error);
    }
  };
  /*************************************
   *
   *************************************/
  @action removeMarketing = async () => {
    
    this.bServerFolders.splice(13, 1);
  }


  /*************************************
   *
   *************************************/

  @action loadInitiations = async () => {
    console.log("loading initiations...");
    this.loadingInitial = true;

    try {
      const response = await agent.Initiation.list(this.axiosParams);

      let pagination = JSON.parse(response.headers.pagination);
      let count = pagination.totalItems;
      this.setCount(count);

      runInAction("loading initiations", () => {
        this.managers = response.data.managersToReturn;
        this.admins = response.data.admins;

        this.iCompanies = response.data.initiationCompanies;
        this.suffixes = response.data.suffixes;
        this.bProjectTemplates = response.data.projectTemplates;
        this.bServerFolders = response.data.serverFolders;
        this.municipalities = response.data.municipalities;

        //this.states = response.data.states;
        //this.clientTypes = response.data.clientTypes;

        response.data.initiations.forEach((initiation: IInitiation) => {
          //console.log('conflict: ' + JSON.stringify(conflict))

          const iDate = new Date(initiation.createDate);
          initiation.createDate = new Date(
            iDate.getTime() - iDate.getTimezoneOffset() * 60000
          );

          this.initiationRegistry.set(initiation.id, initiation);
        });

        //console.log(this.conflictRegistry);
        this.loadingInitial = false;
      });
    } catch (error) {
      runInAction("load initiations error", () => {
        this.loadingInitial = false;
      });

      //console.log(error);
    }
  };

  /*************************************
   *
   *************************************/

  getInitiation = (id: number) => {
    return this.initiationRegistry.get(id);
  };

  /*************************************
   *
   *************************************/

  @action loadInitiation = async (id: number) => {
    let initiation = this.getInitiation(id);

    if (initiation) {
      //console.log(conflict);
      this.initiation = initiation;
      //this.conflict.createDate = new Date(conflict.createDate);
      return initiation;
    } else {
      this.loadingInitial = true;

      try {
        //let conflict = await agent.Conflict.details(id);
        const response = await agent.Initiation.details(id);

        runInAction("getting initiation", () => {
          this.managers = response.managersToReturn;
          this.admins = response.admins;

          this.iCompanies = response.initiationCompanies;
          this.suffixes = response.suffixes;
          this.bProjectTemplates = response.projectTemplates;
          this.bServerFolders = response.serverFolders;
          this.municipalities = response.municipalities;

          //this.states = response.states;
          //this.clientTypes = response.clientTypes;

          initiation = response.initiation;

          //conflict.conflict.createDate = new Date(conflict.conflict.createDate);
          //this.conflict = conflict.conflict;

          const iDate = new Date(initiation.createDate);
          initiation.createDate = new Date(
            iDate.getTime() - iDate.getTimezoneOffset() * 60000
          );

          //conflict.createDate = new Date(conflict.createDate);

          this.initiation = initiation!;

          this.initiationRegistry.set(initiation.id, initiation);

          this.loadingInitial = false;
        });
        return initiation;
      } catch (error) {
        runInAction("get initiation error", () => {
          this.loadingInitial = false;
        });

        console.log(error);
      }
    }
  };

  /*************************************
   *
   *************************************/

  @action createInitiation = async (initiation: IInitiation) => {
    this.submitting = true;
    try {
      const response = await agent.Initiation.createInitiation(initiation);

      runInAction("create initiation", () => {
        this.initiationRegistry.set(initiation.id, initiation);
        this.submitting = false;
      });

      history.push(`/`);
      toast.success("New Project Initiation Added");
    } catch (error: any) {
      runInAction("create initiation form error", () => {
        this.submitting = false;
      });

      //toast.error("Problem submitting data");     //mgd+112222 - comment to avoid duplicate message
      console.error(error.response);
    }
  };

  /*************************************
   *
   *************************************/

  @action createExistingInitiation = async (initiation: IInitiation) => {
    this.submitting = true;
    
    try {

      const response = await agent.Initiation.createExistingInitiation(
        initiation
      );
      

      runInAction("create existing initiation", () => {
        this.initiationRegistry.set(initiation.id, initiation);
        this.submitting = false;
      });

      history.push(`/`);
      toast.success("New Project Initiation Added");
    } catch (error: any) {
      runInAction("create existing initiation form error", () => {
        this.submitting = false;
      });

      toast.error("Problem submitting data");
      console.error(error.response);
    }
  };

  /*************************************
   *
   *************************************/
  /*
  @action addConflictResponse = async (conflict: IConflict) => {
    this.submitting = true;

    try {
      await agent.Conflict.addResponse(conflict.id, conflict);

      runInAction("create conflict response", () => {
        this.conflictRegistry.set(conflict.id, conflict);
        this.submitting = false;
      });

      history.push(`/`);
      //history.push(`/conflict/${conflict.id}`);
      toast.success("New Conflict Response Added");
    } catch (error: any) {
      runInAction("create conflict response error", () => {
        this.submitting = false;
      });

      toast.error("Problem submitting data");
      console.error(error.response);
    }
  };
*/

  /*************************************
   *
   *************************************/
  /*
  @action editInitiation = async (initiation: IInitiation) => {
    this.submitting = true;

    try {
      console.log("submitting??");
      await agent.Conflict.edit(conflict);

      runInAction("editing conflict", () => {
        this.conflictRegistry.set(conflict.id, conflict);
        this.conflict = conflict;
        this.submitting = false;
      });

      history.push(``);
      toast.success("Conflict Report Updated");
    } catch (error: any) {
      runInAction("edit conflict error", () => {
        this.submitting = false;
      });

      toast.error("Problem submitting data");
      console.log(error.response);
    }
  };
*/
}
