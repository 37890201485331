import axios, { AxiosResponse } from "axios";
import { history } from "../..";
import { toast } from "react-toastify";

import { AuthProvider } from "../layout/AuthProvider";

import { IConflict } from "../models/conflict";
import { IInitiation } from "../models/initiation";
import { IClient } from "../models/client";
import { IDSProject } from "../models/dsProject";
import { IProject } from "../models/project";
import { IAdminAccess } from "../models/adminAccess";
import { IDigitalSignature } from "../models/digitalSignature";
import { IMunicipality} from "../models/municipality";
import { ITask } from "../models/task";


// TODO flip this to run in prod. change a flag to remove this hassel
//axios.defaults.baseURL = "http://localhost:5000/api";
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(
  async (config) => {
    const token = (await AuthProvider.getAccessToken()).accessToken;

    //console.log("accessToken: " + token);

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(undefined, (error) => {
  if (error.message === "Network Error" && !error.response) {
    toast.error("Network error - make sure API is running!");
  }

  const { status, data, config } = error.response;

  if (status === 404) {
    history.push("/notfound");
  }

  if (
    status === 400 &&
    config.method === "get" &&
    data.errors.hasOwnProperty("id")
  ) {
    history.push("/notfound");
  }

  if (status === 500) {
    //toast.error("Server error - check the terminal for more info!");
    toast.error("Error submitting request. Try submitting again");        //mgd+112222 - change message
  }

  throw error;
});

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

/* const sleep = (ms: number) => (response: AxiosResponse) =>
  new Promise<AxiosResponse>((resolve) =>
    setTimeout(() => resolve(response), ms)
  );
 */

const requests = {
  // improve performance by removing sleep func ... similated as part of dev

  //get: (url: string) => axios.get(url).then(sleep(1000)).then(responseBody),
  //get: <T> (url: string, params?: URLSearchParams) => axios.get<T>(url, { params : params } ).then(responseBody),
  get: <T>(url: string) => axios.get<T>(url).then(responseBody),

  //post: (url: string, body: {}) => axios.post(url, body).then(sleep(1000)).then(responseBody),
  post: <T>(url: string, body: {}) =>
    axios.post<T>(url, body).then(responseBody),

  //put: (url: string, body: {}) => axios.put(url, body).then(sleep(1000)).then(responseBody),
  put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),

  //del: (url: string) => axios.delete(url).then(sleep(1000)).then(responseBody),
  del: (url: string) => axios.delete(url).then(responseBody),
};

const Conflict = {
  newConflictInfo: () => requests.get<any>("/conflict/newConflictInfo"),

  list: (params: URLSearchParams) =>
    axios.get("/conflict/allConflicts", { params: params }),

  //list: (params: URLSearchParams) =>
  //  requests.get<any>("/conflict/allConflicts", params ),

  details: (id: number) => requests.get<any>(`/conflict/${id}`),
  createConflict: (conflict: IConflict) =>
    requests.post<void>("/conflict", conflict),
  addResponse: (id: number, conflict: IConflict) =>
    requests.put<void>(`/conflict/addResponse/${id}`, conflict),
  edit: (conflict: IConflict) =>
    requests.put<void>(`/conflict/${conflict.id}`, conflict),
};

const Project = {
  list: (params: URLSearchParams) => axios.get("/project/searchProjects", {params: params}),
  details: (code: string) => requests.get<any>(`/project/${code}`)
};

const Initiation = {
  newInitiationInfo: () => requests.get<any>("/initiation/newInitiationInfo"),

  list: (params: URLSearchParams) => axios.get("/initiation/allInitiations", { params: params }),

  details: (id: number) => requests.get<any>(`/initiation/${id}`),
  createInitiation: (initiation: IInitiation) => requests.post<void>("/initiation", initiation),
  createExistingInitiation: (initiation: IInitiation) => requests.post<void>("/initiation/existing", initiation),
  edit: (initiation: IInitiation) => requests.put<void>(`/initiation/${initiation.id}`, initiation),
  bServerClients: (serverFolderId: number): Promise<IClient[]> => requests.get(`initiation/serverfolder/${serverFolderId}`),

  searchProjects: (searchVal: string): Promise<IProject[]> => requests.get(`initiation/searchProjects/${searchVal}`),

  adminAccess: (): Promise<IAdminAccess[]> => requests.get("initiation/adminaccess"),
  projectInfo: (projectNumber: string) => requests.get<any>(`/initiation/${projectNumber}`),
  municipalityList: () => requests.get<any>("initiation/municipalityList"),

};

const AdminTools = {
  addMunicipality: (municipality: IMunicipality) => requests.post("adminTools/addMunicipality", municipality)
}

const DigitalSignature = {
  searchProjects: (searchVal: string): Promise<IDSProject[]> => requests.get(`digitalSignature/searchProjects?searchVal=${searchVal}`),
  listTasks: (project: string): Promise<ITask[]> => requests.get(`digitalSignature/tasks?project=${project}`),
  create: (digitalSignature: IDigitalSignature) => requests.post('/digitalSignature/create', digitalSignature)
};


const agent = {
  Conflict,
  Project,
  Initiation,
  DigitalSignature,
  AdminTools
};

export default agent;
