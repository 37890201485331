import React, { useContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Header, Button, Form, Grid, Segment, Label, CheckboxProps, Select, Input, Dropdown, DropdownOnSearchChangeData, DropdownProps } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { Form as FinalForm, Field } from "react-final-form";
import { combineValidators, composeValidators, isAlphabetic, isRequired, isRequiredIf } from "revalidate";
import { RouteComponentProps } from "react-router-dom";
import arrayMutators from "final-form-arrays";


import { ErrorMessage, Formik, useField, validateYupSchema, yupToFormErrors } from "formik";
import * as Yup from 'yup';

import { RootStoreContext } from "../../app/stores/rootStore";
import PageTitle from "../../app/common/form/PageTitle";
import CheckboxInput from "../../app/common/form/CheckboxInput";
import TextInput from "../../app/common/form/TextInput";
import SelectInput from "../../app/common/form/SelectInput";
import RenderIf from "../../app/common/form/RenderIf";
import { IMunicipality, IMunicipalityFormValues } from "../../app/models/municipality";
import { ISuffix } from "../../app/models/suffix";
import TextInputFormik from "../../app/common/form/TextInputFormik";
import SelectInputFormik from "../../app/common/form/SelectInputFormik";
import "../../app/layout/styles.css";
import "../../app/layout/formStyles.css";

var isExisting = false;



interface DetailParams {
    id: string;
}


const AddMunicipality: React.FC<RouteComponentProps<DetailParams>> = ({
    match,
}) => {
    const rootStore = useContext(RootStoreContext);
    const { accountInfoUserName, accountInfoUserEmail } = rootStore.userStore;
    const {
        submitting,
        getExistingMunicipalities,
        municipalities,
        addMunicipality
    } = rootStore.municipalityStore; //change to municipalityStore

    
    
    const [flag, setFlag] = useState(false);
    const [municipality, setMunicipality] = useState(new IMunicipalityFormValues());
    

    const [loading, setLoading] = useState(false);

    useEffect(() => {
            setLoading(true);
            getExistingMunicipalities().finally(() => setLoading(false));
    }, []);

    //map just the municipal codes to a string array for Yup to compare against
    const existingMunicipalities: string[] = municipalities.map(m => m.code);


    const handleFinalFormSubmit = (values: IMunicipalityFormValues) => {
        console.log("submitting municipality");
        //trim trailing whitespace
        values.name.trim();
        values.code.trim();

        addMunicipality(values);
        
    };
    
    
    const validationSchema = Yup.object({
        name: Yup.string().required("Name is required.").max(140, "Maximum character length exeeded."),
        code: Yup.string().required('Municipal Code is required.').notOneOf(existingMunicipalities, "Municipal Code already exists.").matches(/^[^a-z]*$/, 'Code must not contain lowercase letters.').matches(/^\S*$/, 'Code must not contain spaces').max(5, "Maximum code length exceeded.") 
    });

    return (
        <>
            <PageTitle title="Add Municipality" />
            <Grid>
                <Grid.Column width={16}>
                    <Segment clearing>
                        <Formik
                            validationSchema={validationSchema}
                            enableReinitialize={true}
                            initialValues={municipality}
                            onSubmit={handleFinalFormSubmit}
                        >
                        {({ submitForm, setFieldTouched, values, isSubmitting, isValid, errors, touched}) => (
                            <Form className= 'ui form'>
                                <Header as="h3">Add New Municipality</Header>
                                <Segment>
                                    <Form.Group widths="equal" className="field">
                                        
                                        <Form.Field className="fieldDiv" error={touched.name && errors.name}>
                                            <TextInputFormik name="name" placeholder="Enter Municipal Name" />
                                            </Form.Field>
                                            <Form.Field className="fieldDiv" error={touched.code && errors.code}>
                                                <TextInputFormik name="code" placeholder="Enter Municipal Code" />
                                            </Form.Field>
                                        
                                    </Form.Group>
                                </Segment> 
                                    <Button 
                                        loading={submitting}
                                        floated="right"
                                        positive                     
                                        disabled={(isSubmitting || !isValid || (Object.keys(touched).length === 0))}
                                        type="submit"
                                        content="Submit"
                                        onClick={submitForm}
                                    />
                            </Form>
                        )}
                        </Formik> 
                        
                    </Segment>
                    </Grid.Column>
                    </Grid>   
        </>
    );
};

export default observer(AddMunicipality);