import React, { useContext, useEffect, useState } from 'react'
import { observer } from "mobx-react-lite";
import InfiniteScroll from 'react-infinite-scroller';

import LoadingComponent from "../../../app/layout/LoadingComponent";
import { RootStoreContext } from "../../../app/stores/rootStore";
import PageTitle from "../../../app/common/form/PageTitle";
import InitiationList from './InitiationList';
import InitiationSearch from './InitiationSearch';

const InitiationDashboard: React.FC = () => {

  const rootStore = useContext(RootStoreContext);
  const { loadInitiations, loadingInitial, setPage, page, totalPages } = rootStore.initiationStore;

  const [loadingNext, setLoadingNext] = useState(false);

  const handleGetNext = () => {
    setLoadingNext(true);
    setPage(page + 1);
    loadInitiations().then(() => setLoadingNext(false));
    console.log('page: ' + page);
    console.log('totalPages: ' + totalPages);
  }

  useEffect(() => {
    loadInitiations();
    //console.log('page: ' + page);
    //console.log('totalPages: ' + totalPages);
  }, [loadInitiations]);

  if (loadingInitial && page === 0)
    return <LoadingComponent content="Loading initiations..." />;
  else



  return (
    <>
      <PageTitle title="All Initiation Requests" />

      <InitiationSearch />

      <InfiniteScroll pageStart={0} loadMore={handleGetNext} hasMore={!loadingNext && page + 1 < totalPages} initialLoad={false} >
        <InitiationList />
      </InfiniteScroll>

    </>
  )
}

export default observer(InitiationDashboard);
