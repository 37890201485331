import React, { useContext, useEffect, useState } from 'react'
import { observer } from "mobx-react-lite";
import InfiniteScroll from 'react-infinite-scroller';

import LoadingComponent from "../../../app/layout/LoadingComponent";
import { RootStoreContext } from "../../../app/stores/rootStore";
import PageTitle from "../../../app/common/form/PageTitle";

import ProjectList from '../dashboard/ProjectList';
import ProjectSearch from './ProjectSearch';


const ProjectDashboard: React.FC = () => {
    const rootStore = useContext(RootStoreContext);

    const { loadConflicts } = rootStore.conflictStore;
    const { loadProjects, loadingInitial, setPage, page, totalPages } = rootStore.projectStore;
    const [loadingNext, setLoadingNext] = useState(false);

    const handleGetNext = () => {
        setLoadingNext(true);
        setPage(page + 1);
        loadProjects().then(() => setLoadingNext(false));

    }

    useEffect(() => {
       loadConflicts();
        loadProjects();
    }, [loadProjects, loadConflicts]);

    if (loadingInitial && page === 0)
    return <LoadingComponent content="Loading reports..." />;
    else

    return (
        <>
            <div className='noPrint'>
            <PageTitle title="Project Search" />
            </div>
            <ProjectSearch />
            <InfiniteScroll 
            pageStart={0} 
            loadMore={handleGetNext} 
            hasMore={!loadingNext && page + 1 < totalPages} 
            initialLoad={false}   
            >
                <ProjectList />
            </InfiniteScroll>
        </>
    )
}

export default observer(ProjectDashboard);