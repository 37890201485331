import React, { useContext, Fragment } from "react";
import NavBar from "../../features/nav/NavBar";
import Footer from "../../features/footer/Footer";
import { Container } from "semantic-ui-react";

import { observer } from "mobx-react-lite";
import "mobx-react-lite/batchingForReactDom"; // https://stackoverflow.com/questions/61654633/mobx-react-console-warning-related-observer

import {
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from "react-router-dom";
import Home from "../../features/home/Home";
import NotFound from "./NotFound";
import { ToastContainer } from "react-toastify";
import AzureAD, { IAzureADFunctionProps } from "react-aad-msal";
import { AuthProvider } from "./AuthProvider";
import ConflictForm from "../../features/conflict/form/ConflictForm";
import InitiationForm from "../../features/initiation/form/InitiationForm";
import ConflictDetails from "../../features/conflict/details/ConflictDetails";
import ProjectDetails from "../../features/conflict/details/ProjectDetails";
import DigitalSignatureForm from "../../features/digitalsignature/DigitalSignatureForm";

import ModalContainer from "../common/modals/ModalContainer";

import { RootStoreContext } from "../stores/rootStore";
import ConflictDashboard from "../../features/conflict/dashboard/ConflictDashboard";
import InitiationDashboard from "../../features/initiation/dashboard/InitiationDashboard";
import SearchDashboard from "../../features/conflict/projectsearch/SearchDashboard";
import ExistingInitiationForm from "../../features/existingInitiation/form/ExistingInitiationForm";
import AddMunicipality from "../../features/admintools/AddMunicipality";

const App: React.FC<RouteComponentProps> = ({ location }) => {
  const rootStore = useContext(RootStoreContext);
  const { setAccountInfo } = rootStore.userStore;

  return (
    <AzureAD provider={AuthProvider} forceLogin={true}>
      {({
        login,
        logout,
        accountInfo,
        authenticationState,
        error,
      }: IAzureADFunctionProps) => {
        setAccountInfo(accountInfo!);
        // console.log("accountInfo is " + JSON.stringify(accountInfo))

        return (
          <Fragment>
            <ModalContainer />
            <ToastContainer position='bottom-right' />
            <NavBar
              login={login}
              logout={logout}
              authenticationState={authenticationState}
            />

            <Container style={{ marginTop: "4em" }}>
              <Switch>
                <Route exact path='/' component={Home} />
                <Route exact path='/existingInitiation' component={ExistingInitiationForm} />
                <Route exact path='/createInitiation' component={InitiationForm} />
                <Route exact path='/createConflict' component={ConflictForm} />
                <Route exact path='/initiations' component={InitiationDashboard} />
                <Route exact path='/conflicts' component={ConflictDashboard} />
                <Route exact path='/projectsearch' component={SearchDashboard} />
                <Route exact path='/conflict/:id' component={ConflictDetails} />
                <Route exact path='/project/:code' component={ProjectDetails} />
                <Route exact path='/new-digital-signature' component={DigitalSignatureForm} />
                <Route key={location.key} path={"/editConflict/:id"} component={ConflictForm} />
                <Route exact path='/tools/addMunicipality' component={AddMunicipality} />
                <Route component={NotFound} />
              </Switch>
            </Container>

            <div className='margin_bottom_40'></div>
            <div className='margin_bottom_40'></div>

            <Footer />
          </Fragment>
        );
      }}
    </AzureAD>
  );
};

export default withRouter(observer(App));
