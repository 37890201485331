import React, { useContext, useEffect } from "react";
import { Grid, Segment, Button, Header, Icon , Table, Menu, Item} from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { RouteComponentProps } from "react-router-dom";
import { Link } from "react-router-dom";

import LoadingComponent from "../../../app/layout/LoadingComponent";
import { RootStoreContext } from "../../../app/stores/rootStore";
import PageTitle from "../../../app/common/form/PageTitle";
import ConflictResponseForm from '../form/ConflictResponseForm'
import { IConflictResponse } from "../../../app/models/conflictResponse";
import {format} from 'date-fns';

interface DetailParams {
  id: string;
}

const ConflictDetails: React.FC<RouteComponentProps<DetailParams>> = ({
  match,
  //history,
}) => {
  const rootStore = useContext(RootStoreContext);
  const { accountInfoUserEmail } = rootStore.userStore;
  const { conflict, loadConflict, loadingInitial, states, clientTypes, responseFormVisible, setResponseFormVisible } = rootStore.conflictStore;
  
  useEffect(() => {
    loadConflict(parseInt(match.params.id));
  }, [loadConflict, match.params.id]);


  const getStateName = (value: number) => {
    const state = states.filter(state => state.id === value)
    return state[0].stateName;
  };

  const getClientTypeName = (value: number) => {
    const clientType = clientTypes.filter(clientType => clientType.id === value)
    if (!clientType) return "Client Types not loaded - Please refresh the page."
    return clientType[0].clientTypeName;
  };

  const getResponseDateTime = (responseDate: Date) => {
    let rDate = new Date(responseDate);
    rDate = new Date(rDate.getTime() - rDate.getTimezoneOffset() * 60000) 
    return format(rDate, 'MM/dd/yyyy h:mm aa');
  };

  if (!conflict || loadingInitial)
    return <LoadingComponent content="Loading conflict ..." />;

  if (!conflict) return <h2>Conflict Not Found</h2>;

  return (
    <>
      <PageTitle title="Conflict Check Details" />
      <Segment clearing>
     
        <Grid columns={2} stackable>
          <Grid.Column>

          <Table compact basic='very'>
            <Table.Body>
              <Table.Row>
                <Table.Cell>Project Manager</Table.Cell>
                <Table.Cell className='bold' textAlign="right">
                  
                   <a href={`https://employeedirectory.collierseng.com/employee/${conflict.projectManagerEmail}/`} target='_blank'><u>{conflict.projectManagerName}</u></a></Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Project Name</Table.Cell>
                <Table.Cell className='bold' textAlign="right">{conflict.projectName}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Company</Table.Cell>
                <Table.Cell className='bold' textAlign="right">{conflict.company}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Company Contact (Ext)</Table.Cell>
                <Table.Cell className='bold' textAlign="right">{conflict.companyContact}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Location</Table.Cell>
                <Table.Cell className='bold' textAlign="right">{conflict.location}, {getStateName(parseInt(conflict.state))}</Table.Cell>
            </Table.Row>
            </Table.Body>
          </Table>
        </Grid.Column>
        
        <Grid.Column>
          <Table compact basic='very'>
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  Client Type:{" "}
                  <span className="floatRight bold">{getClientTypeName(parseInt(conflict.clientType))}</span>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  Appointed 
                  <span className="floatRight bold">
                    {conflict.appointed == '0' && 'Unknown'}
                    {conflict.appointed == '1' && <Icon name='check' color='green' />}
                    {conflict.appointed == '2' && <Icon name='x' color='red' />}
                  </span>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  Confidential
                  <span className="floatRight bold">
                    {conflict.confidential ? <Icon name='check' color='green'/> : <Icon name='x' color='red' />}
                  </span>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  Has Railroad Component{" "}
                  <span className="floatRight bold">
                    {conflict.hasRailroadComponent ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
                  </span>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table> 
         </Grid.Column>
        </Grid>

        <Grid stackable>
          <div id='conflictDesc'>Description:<br /> <b>{conflict.description}</b></div>

          <Grid.Row columns={3}>
            <Grid.Column>
              <div>Total Responses:{' '}<b>{conflict.responses}</b></div>
            </Grid.Column>
            <Grid.Column>
              <div>Submitted by:{' '}

              <b><a href={`https://employeedirectory.collierseng.com/employee/${conflict.createdByEmail}/`} target='_blank'><u>{conflict.createdByName}</u></a></b></div>
            </Grid.Column>
            <Grid.Column>
            <div>Submitted on:{' '}<b>{ format(conflict.createDate, 'MM/dd/yyyy') } at { format(conflict.createDate, 'h:mm aa') } </b></div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>

      {(conflict.createdByEmail === accountInfoUserEmail || conflict.projectManagerEmail === accountInfoUserEmail) &&
        <Button
          floated='right'
          color='orange'
          content='Edit'
          as={Link}
           to={`/editConflict/${conflict.id}`} 
        />
      }
      
      {!responseFormVisible &&
        <Button color="green" onClick={() => setResponseFormVisible(true)}>
          Add Response
        </Button>
      }
      <ConflictResponseForm />

      {conflict.responses > 0 && <>
        <Segment>
          <Header>Responses ({conflict.responses})</Header>

          {conflict.conflictResponses.sort((a: IConflictResponse, b: IConflictResponse) => {
            return +new Date(b.createDate) - +new Date(a.createDate);
          }).map((response, key) =>

            <>
              <Segment key={key}>

                <Grid stackable>
                  <Grid.Row columns={2}>
                    <Grid.Column width={13}>
                      {response.description}
                    </Grid.Column>

                    <Grid.Column width={3}>
                      <div>
                        Conflict: <span className='conflictDetail'>{response.conflict ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}</span><br />
                        History: <span className='conflictDetail'>{response.history ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}</span><br />
                        Relationship: &nbsp; <span className='conflictDetail'>{response.relationship ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}</span><br />
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>

                <br />
                <Icon name="user" color="blue" /> by <a href={`https://employeedirectory.collierseng.com/employee/${conflict.projectManagerEmail}/`} target='_blank'>{response.createdByName}</a> &nbsp;&nbsp;
                <Icon name="calendar alternate outline" color="green" /> on { getResponseDateTime(response.createDate) }
                <br />

              </Segment>
            </>
          )}
        </Segment>

      </>
      }
    </>
  );
};

export default observer(ConflictDetails);