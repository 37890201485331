import React from "react";
import { Link } from "react-router-dom";
import { Button, Icon, Item, Segment, Menu } from "semantic-ui-react";
import { IConflict } from "../../../app/models/conflict";
import { observer } from 'mobx-react-lite';
import { getProfileLink } from "../../../app/common/util/util";
import { format } from 'date-fns';

const ConflictListItem: React.FC<{ conflict: IConflict }> = ({ conflict }) => {

  return (
    <Segment.Group>
      <Segment>
        <Item.Group>
          <Item>
            <Item.Content>
              <Item.Header>Project: {conflict.projectName}</Item.Header>
{/*         <Item.Meta>Manager: <a href={getProfileLink(conflict.projectManagerEmail)} target="_blank">{conflict.projectManagerName}</a></Item.Meta> */}
              <Item.Meta>Manager: {' '}
                <mark className="magenta"><a href={`https://employeedirectory.collierseng.com/employee/${conflict.projectManagerEmail}/`} target='_blank'><u>{conflict.projectManagerName}</u></a></mark></Item.Meta>

              <Item.Description>{conflict.companyContact} | {conflict.company}</Item.Description>
            </Item.Content>
          </Item>
        </Item.Group>
      </Segment>
      <Segment secondary>
{/*        <Icon name="user" /> by <a href={getProfileLink(conflict.createdByEmail)} target='_blank'>{conflict.createdByName}</a><br /> */}

        <Icon name="user" color="blue" /> by {' '}
                <mark className="magenta"><a href={`https://employeedirectory.collierseng.com/employee/${conflict.createdByEmail}/`} target='_blank'><u>{conflict.createdByName}</u></a></mark><br />
        <Icon name="calendar alternate outline" color="green"/> on { format(conflict.createDate, 'MM/dd/yyyy h:mm aa') }
        {/* <Icon name="calendar alternate outline" /> on {conflict.createDate.toLocaleString()} */}
      </Segment>
      <Segment>{conflict.description}</Segment>
      {/* <Segment secondary>Quick Look</Segment> */}
      <Segment clearing>
        {conflict.responses ? conflict.responses : 'No' } response{conflict.responses !== 1 ? 's' : ''}.
        <Button
          as={Link}
          to={`/conflict/${conflict.id}`}
          floated="right"
          content="View"
          color="blue"
        />
      </Segment>
    </Segment.Group>
  );
};

export default observer(ConflictListItem);
