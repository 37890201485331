import { observable, action, runInAction, computed } from "mobx";
import { toast } from "react-toastify";
import { history } from "../..";
import { RootStore } from "./rootStore";
import agent from "../api/agent";

//import { IConflict } from "../models/conflict";
import { IInitiation } from "../models/initiation";

import { IAdmin } from "./../models/admin";
import { IManager } from "../models/manager";

import { IMunicipality } from "./../models/municipality";
import { IServerFolder } from "./../models/serverFolder";
import { IProjectTemplate } from "./../models/projectTemplate";
import { ISuffix } from "./../models/suffix";
import { IInitiationCompany } from "./../models/initiationCompany";
import { IClient } from "./../models/client";
import { IProject } from "../models/project";
import { IAdminAccess } from "../models/adminAccess";

export default class MunicipalityStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }


    @observable municipalities: IMunicipality[] = [];
    @observable submitting = false;
    @observable loadingInitial = false;
    

    @action getExistingMunicipalities = async () => {
        //console.log("Loading all municipalities...");
        this.loadingInitial = true;
        try {
            const response = await agent.Initiation.municipalityList();
            //console.log("test after response");
            runInAction("Loading all municipal info", () => {
                this.municipalities = response.municipalities;

                this.loadingInitial = false;
            });

        } catch (error) {
            runInAction("load existing municipalities info error", () => {
                this.loadingInitial = false;

            });
            console.error(error);
        }


    }

    @action addMunicipality = async (municipality: IMunicipality) => {
        this.submitting = true;
        

        try {
            console.log("Creating municipality: " + municipality.name + " " + municipality.code);
            
            
            const response = await agent.AdminTools.addMunicipality(municipality);

            
            console.log("Test past runInAction");
            history.push(`/`); //Redirect to home
            toast.success("New Municipality Added!");
        }
        catch (error: any) {
            runInAction("Create municipality error", () => {
                this.submitting = false;
            });
            console.error(error.response);
        }

    }




}
