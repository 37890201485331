import UserStore from './userStore';
import { createContext } from 'react';
import { configure } from 'mobx';
import ModalStore from './modalStore';
import ConflictStore from './conflictStore';
import InitiationStore from './initiationStore';
import ProjectStore from './projectStore';
import DigitalSignatureStore from './digitalSignatureStore';
import MunicipalityStore from './municipalityStore';

configure({ enforceActions: 'always' });

export class RootStore {
    userStore: UserStore;
    modalStore: ModalStore;
    conflictStore: ConflictStore;
    initiationStore: InitiationStore;
    projectStore: ProjectStore;
    digitalSignatureStore: DigitalSignatureStore;
    municipalityStore: MunicipalityStore;

    constructor() {
        this.userStore = new UserStore(this);
        this.modalStore = new ModalStore(this);
        this.conflictStore = new ConflictStore(this);
        this.initiationStore = new InitiationStore(this);
        this.projectStore = new ProjectStore(this);
        this.digitalSignatureStore = new DigitalSignatureStore(this);
        this.municipalityStore = new MunicipalityStore(this);
    }
}

export const RootStoreContext = createContext(new RootStore());