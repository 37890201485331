import { IConflictResponse } from '../models/conflictResponse';

export interface IConflict {
    id: number;
    projectManagerName: string; //?
    projectManagerEmail: string; //
    confidential: boolean; //
    companyContact: string; //
    company: string; //

    //clientType: number;
    clientType: string; //

    hasRailroadComponent: boolean; //

    //appointed: number;
    appointed: string; //

    projectName: string; //
    location: string; //

    //state: number;
    state: string;

    description: string;
    responses: number;
    conflictResponses: IConflictResponse[];
    createdByName: string;
    createdByEmail: string;
    createDate: Date;
    InConflictReportEmail: boolean;
    HasUpdate: boolean;
}

export class IConflictFormValues implements IConflict {
    id: number = 0;
    projectManagerName: string = '';
    projectManagerEmail: string = '';
    confidential: boolean = false;
    companyContact: string = '';
    company: string = '';

    //clientType: number = 0;
    clientType: string = '';

    hasRailroadComponent: boolean = false;

    //appointed: number = -1;
    appointed: string = '';

    projectName: string = '';
    location: string = '';

    //state: number = 0;
    state: string = '';

    description: string = '';
    responses: number = 0;
    conflictResponses: IConflictResponse[] = [];
    createdByName: string = '';
    createdByEmail: string = '';
    createDate: Date = new Date(Date.now());
    
    InConflictReportEmail: boolean = false;
    HasUpdate: boolean = false;

    constructor(init?: IConflictFormValues) {
        Object.assign(this, init);
    }
}