import React from "react";
import { Link } from "react-router-dom";
import { Button, Icon, Item, Segment } from "semantic-ui-react";
import { IConflict } from "../../../app/models/conflict";
import { IInitiation } from "../../../app/models/initiation";
import { observer } from 'mobx-react-lite';
import { getProfileLink } from "../../../app/common/util/util";
import { format } from 'date-fns';
import RenderIf from "../../../app/common/form/RenderIf";

const InitiationListItem: React.FC<{ initiation: IInitiation }> = ({ initiation }) => {

  const getProjectTypeNumberString = () => {

    let projectString;

    if (initiation.isMunicipal) {
      projectString = "Municipal Project Number " + initiation.prefix + initiation.number + initiation.suffix;
    } else {
      projectString = "Project Number " + initiation.year + initiation.number + initiation.suffix;
    }

    return projectString;
  };


  return (
    <Segment.Group>
      <Segment>
        <Item.Group>
          <Item>
            <Item.Content>

              <Item.Header>{getProjectTypeNumberString()}</Item.Header>

{/*         <Item.Meta>Manager: <a href={getProfileLink(conflict.projectManagerEmail)} target="_blank">{conflict.projectManagerName}</a></Item.Meta> */}
              <Item.Meta>Project Name: {initiation.name}</Item.Meta>
              <Item.Meta>Project Manager: <mark className="magenta"><a href={`https://employeedirectory.collierseng.com/employee/${initiation.projectManagerEmail}/`} target='_blank'><u>{initiation.projectManagerName}</u></a></mark></Item.Meta>

              <RenderIf condition={initiation.projectCreated}>
              <Item.Meta><mark className="green">Project Folder Created</mark></Item.Meta>
              </RenderIf>
              <RenderIf condition={!initiation.projectCreated}>
              <Item.Meta><mark className="red">Project Folder Creation Pending</mark></Item.Meta>
              </RenderIf>


{/* 
              <Item.Description>{conflict.companyContact} | {conflict.company}</Item.Description>
*/}
            </Item.Content>
          </Item>
        </Item.Group>
      </Segment>
      <Segment secondary>
{/*        <Icon name="user" /> by <a href={getProfileLink(conflict.createdByEmail)} target='_blank'>{conflict.createdByName}</a><br /> */}
        <Icon name="user" color="blue" /> by <mark className="magenta"><a href={`https://employeedirectory.collierseng.com/employee/${initiation.createdByEmail}/`} target='_blank'><u>{initiation.createdByName}</u></a></mark><br />
        <Icon name="calendar alternate outline" color="green"/> on { format(initiation.createDate, 'MM/dd/yyyy h:mm aa') }
        {/* <Icon name="calendar alternate outline" /> on {conflict.createDate.toLocaleString()} */}
      </Segment>

{/* 
      <Segment>{conflict.description}</Segment>
*/}

      {/* <Segment secondary>Quick Look</Segment> */}

{/* 
      <Segment clearing>
        {conflict.responses ? conflict.responses : 'No' } response{conflict.responses !== 1 ? 's' : ''}.
        <Button
          as={Link}
          to={`/conflict/${conflict.id}`}
          floated="right"
          content="View"
          color="blue"
        />
      </Segment>
*/}

    </Segment.Group>
  );
};

export default observer(InitiationListItem);
