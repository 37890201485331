import React, { useContext } from "react";
import { Button, Grid, Image, Segment } from "semantic-ui-react";
import { RootStoreContext } from "../../app/stores/rootStore";

const AboutForm = () => {
  const rootStore = useContext(RootStoreContext);
  const {
    modal: { open, body },
    closeModal,
  } = rootStore.modalStore;

  return (
    <Segment clearing>
      <Grid columns={2}>
        <Grid.Column>
          <Image size="small" src="/assets/ced3.png" wrapped />
        </Grid.Column>
        <Grid.Column textAlign="center">
          <h3>Project Resources</h3>
          <h4>September 10, 2024 version 2.0.36</h4>
        </Grid.Column>
      </Grid>

      <Button color="blue" floated="right" onClick={() => closeModal()}>
        Ok
      </Button>
    </Segment>
  );
};

export default AboutForm;
