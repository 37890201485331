import React, { Fragment, useContext} from "react";
import {observer} from 'mobx-react-lite';
import {Item,Grid,Segment} from "semantic-ui-react";
import {RootStoreContext} from "../../../app/stores/rootStore";
import ProjectListItem from "./ProjectListItem";

const ProjectList: React.FC = () => {
    const rootStore = useContext(RootStoreContext);
    const {projectsByDefault} = rootStore.projectStore;


    return (
        <Grid columns={2} stackable>
            <Fragment>
                {projectsByDefault.map((project) => (
                    <Grid.Column key={project.projectCode}>
                        <Segment clearing>
                            <Item.Group>
                                <ProjectListItem key={project.ProjectCode} project={project} />

                            </Item.Group>
                        </Segment>
                    </Grid.Column>
                ))}
            </Fragment>
        </Grid>
    )
}

export default observer(ProjectList);