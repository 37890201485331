import React, { useContext, useState } from "react";
import { Header, Segment, Form, Button, Grid } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { Form as FinalForm, Field } from "react-final-form";

import { RootStoreContext } from "../../../app/stores/rootStore";
import CheckboxInput from "../../../app/common/form/CheckboxInput";
import { IConflict } from "../../../app/models/conflict";
import { IConflictResponse, IConflictResponseFormValues } from "../../../app/models/conflictResponse";
import TextAreaInput from "../../../app/common/form/TextAreaInput";
import { combineValidators, isRequired } from "revalidate";

const validate = combineValidators({
  description: isRequired("Description")
})

/*************************************
 *
 *************************************/

const ConflictResponseForm = () => {
  const rootStore = useContext(RootStoreContext);
  const { accountInfoUserName, accountInfoUserEmail } = rootStore.userStore;
  const { conflict, responseFormVisible, addConflictResponse, submitting } = rootStore.conflictStore;

  const [response, setResponse] = useState(
    new IConflictResponseFormValues()
  );

  /*************************************
   *
   *************************************/
  const handleFinalFormSubmit = (values: IConflictResponseFormValues) => {

    let newResponse: IConflictResponse = {
      ...values,
      createDate: new Date(Date.now()),
      createdByName: accountInfoUserName ? accountInfoUserName : "error: no name loaded in client",
      createdByEmail: accountInfoUserEmail ? accountInfoUserEmail : "error: no email loaded in client"
    }
    console.log(newResponse);

    if (conflict) {
      let newConflict: IConflict = {
        ...conflict,
        conflictResponses: JSON.parse(JSON.stringify(conflict.conflictResponses))
      };

      newConflict.conflictResponses.push(newResponse);
      newConflict.responses = newConflict.conflictResponses.length
      console.log(newConflict);

      addConflictResponse(newConflict);
    }
    else {
      console.error("ERROR: Conflict never instantiated.")
    }
  }

  if (responseFormVisible) {
    return (
      <Segment clearing>
        <Header>Add Response</Header>

        <FinalForm
          onSubmit={handleFinalFormSubmit}
          initialValues={response}
          validate={validate}
          render={({ handleSubmit, form }) => (
            <Form onSubmit={handleSubmit}>
              <Grid id='conflictResponseForm' stackable>
                <Grid.Row>
                  <Grid.Column id='responseCheckboxes'>
                      <Field
                        label='Conflict'
                        name='conflict'
                        component={CheckboxInput}
                        value={response.conflict} />

                      <Field
                        label='Relationship'
                        name='relationship'
                        component={CheckboxInput}
                        value={response.relationship} />

                      <Field
                        label='History'
                        name='history'
                        component={CheckboxInput}
                        value={response.history} />
                  </Grid.Column>
                  <Grid.Column id='responseDesc'>
                    <Field
                      name="description"
                      placeholder="Description"
                      value={response.description}
                      component={TextAreaInput}
                      width={16}
                      rows={4}
                    />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column>
                    <Button
                      loading={submitting}
                      // floated="right"
                      positive
                      type="submit"
                      content="Submit"
                    />
                  </Grid.Column>

                </Grid.Row>
              </Grid>
            </Form>
          )}
        />
      </Segment >
    );
  }
  else {
    return <></>;
  }
};

export default observer(ConflictResponseForm);
