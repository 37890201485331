import React, { useContext, useState } from "react";
import { Segment, Form, Button, Grid } from "semantic-ui-react";
import { Form as FinalForm, Field } from "react-final-form";
import { observer } from "mobx-react-lite";


import { RootStoreContext } from "../../../app/stores/rootStore";
import TextInput from "../../../app/common/form/TextInput";
import SelectInput from "../../../app/common/form/SelectInput";
import { ConflictSearchFormValues } from "../../../app/models/conflictSearch";



const yesNoOption = [
  { key: 1, value: 1, text: 'Yes' },
  { key: 0, value: 0, text: 'No' },
];

const appointedOptions = [
  { key: '0', value: 0, text: 'Unknown' },
  { key: '1', value: 1, text: 'Yes' },
  { key: '2', value: 2, text: 'No' }
]


/*************************************
 *
 *************************************/

const ConflictSearch = () => {

  const rootStore = useContext(RootStoreContext);
  const {
    clientTypes, states,
    setPredicate, clearPredicate, removePredicate,
    loadConflicts,
    clearConflictRegistry,
  } = rootStore.conflictStore;

  const [conflictSearch, setConflictSearch] = useState(new ConflictSearchFormValues());
  const [moreVisible, setMoreVisible] = useState(false);

  /*************************************
   *
   *************************************/
  const handleFinalFormSubmit = (values: ConflictSearchFormValues) => {

    if (values.projectName !== "")
      setPredicate('projectName', values.projectName);
    else
      removePredicate('projectName');

    if (values.company !== "")
      setPredicate('company', values.company);
    else
      removePredicate('company');

    if (values.companyContact !== "")
      setPredicate('companyContact', values.companyContact);
    else
      removePredicate('companyContact');

    if (values.projectManager !== '')
      setPredicate('projectManager', values.projectManager);
    else
      removePredicate('projectManager');

    if (values.description !== '')
      setPredicate('description', values.description);
    else
      removePredicate('description');

    if (values.location !== '')
      setPredicate('location', values.location);
    else
      removePredicate('location');

    if (values.state !== 0)
      setPredicate('state', values.state);
    else
      removePredicate('state');

    if (values.clientType !== -1)
      setPredicate('clientType', values.clientType);
    else
      removePredicate('clientType');

    if (values.confidential !== -1)
      setPredicate('confidential', values.confidential ? 'true' : 'false');
    else
      removePredicate('confidential');

    if (values.appointed !== -1)
      setPredicate('appointed', values.appointed);
    else
      removePredicate('appointed');

    if (values.hasRailroadComponent !== -1)
      setPredicate('hasRailroadComponent', values.hasRailroadComponent ? 'true' : 'false');
    else
      removePredicate('hasRailroadComponent');

    //console.log("hasrailroadcomponent :" + values.hasRailroadComponent);

    clearConflictRegistry();
    loadConflicts();
  }



  /*************************************
   *
   *************************************/
  const resetSearch = (form: any) => {

    clearPredicate();
    form.reset();
    loadConflicts();

  }

  return (
    <Segment clearing>
      <FinalForm
        onSubmit={handleFinalFormSubmit}
        initialValues={conflictSearch}
        render={({ handleSubmit, form }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group widths='equal'>
              <Field
                name="projectName"
                placeholder="Project Name"
                component={TextInput}
                value={conflictSearch.projectName}
              />
              <Field
                name="projectManager"
                placeholder="Manager"
                value={conflictSearch.projectManager}
                component={TextInput}
              />
              <Field
                name="description"
                placeholder="Description"
                component={TextInput}
                value={conflictSearch.description}
              />
            </Form.Group>

            {moreVisible &&
              <>
                <Form.Group widths='equal'>
                  <Field
                    name="company"
                    placeholder="Company"
                    component={TextInput}
                    value={conflictSearch.company}
                  />
                  <Field
                    name="companyContact"
                    placeholder="Company Contact"
                    component={TextInput}
                    value={conflictSearch.companyContact}
                  />
                  <Field
                    name="location"
                    placeholder="Location"
                    component={TextInput}
                    value={conflictSearch.location}
                  />
                  <Field
                    name='state'
                    placeholder='State'
                    value={conflictSearch.state.toString()}
                    search
                    options={states.map(s => ({
                      key: s.id,
                      value: s.id,
                      text: s.stateName
                    }))}
                    component={SelectInput}
                  />
                </Form.Group>

                <Form.Group widths='equal'>
                  <Field
                    name="clientType"
                    placeholder="Client Type"
                    value={conflictSearch.clientType.toString()}
                    component={SelectInput}
                    // options={clientTypeOptions.map(c => ({
                    options={clientTypes.map(c => ({
                      key: c.id,
                      value: c.id,
                      text: c.clientTypeName
                    }))}
                  />

                  <Field
                    name="confidential"
                    placeholder='Confidential'
                    value={conflictSearch.confidential.toString()}
                    component={SelectInput}
                    options={yesNoOption.map(val => ({
                      key: val.key,
                      value: val.value,
                      text: val.text
                    }))}
                  />

                  <Field
                    name="appointed"
                    placeholder="Appointed"
                    value={conflictSearch.appointed.toString()}
                    component={SelectInput}
                    options={appointedOptions.map(val => ({
                      key: val.key,
                      value: val.value,
                      text: val.text
                    }))}
                  />

                  <Field
                    name="hasRailroadComponent"
                    placeholder="Has Railroad Component"
                    value={conflictSearch.hasRailroadComponent.toString()}
                    component={SelectInput}
                    options={yesNoOption.map(val => ({
                      key: val.key,
                      value: val.value,
                      text: val.text
                    }))}
                  />
                </Form.Group>
              </>
            }

            <Grid>
              <Grid.Column textAlign="center">
                <Button positive type="submit" content="Search" />
                {!moreVisible && <Button color="orange" type="button" content="Show More" onClick={() => setMoreVisible(true)} />}
                {moreVisible && <Button color="orange" type="button" content="Hide More" onClick={() => setMoreVisible(false)} />}
                <Button color="red" type="button" content="Reset" onClick={() => resetSearch(form)} />
              </Grid.Column>
            </Grid>
          </Form>
        )
        }
      />
    </Segment >
  );
};

export default observer(ConflictSearch);
