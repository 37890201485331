import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  Container,
  Grid,
  Image,
  Segment,
  Button,
} from "semantic-ui-react";
import PageTitle from "../../app/common/form/PageTitle";
import RenderIf from "../../app/common/form/RenderIf";

import { RootStoreContext } from "../../app/stores/rootStore";

const Home: React.FC = () => {
  const rootStore = useContext(RootStoreContext);

  const { accountInfoUserName, accountInfoUserEmail } = rootStore.userStore;

  const { adminAccess, initiationAdminAccess } = rootStore.initiationStore;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    initiationAdminAccess().finally(() => setLoading(false));
  }, [initiationAdminAccess]);

  const isAdmin = () => {

    const admin = adminAccess.filter(a => a.userEmail === accountInfoUserEmail);

    if (admin.length > 0)
      return true;

    return false;
  };

  return (
    <>
      <PageTitle title='Project Resources Home' />

      {<h2>Welcome {accountInfoUserName}</h2>}

      <Card.Group centered vertical='true' className='masthead'>

        <RenderIf condition={isAdmin()}>
          <Card className='cardShadow'>
            <Card.Content>
              <Card.Header>Initiation</Card.Header>
            </Card.Content>
            <Image src='/assets/Lease-Administration.png' wrapped ui={false} />
            <Card.Content>
              <div className='ui two buttons'>
                <Button basic color='black' href='/initiations'>
                  View All
                </Button>
              </div>
            </Card.Content>
          </Card>
        </RenderIf>

        <Card className='cardShadow'>
          <Card.Content>
            <Card.Header>Initiation</Card.Header>
          </Card.Content>
          <Image src='/assets/Lease-Administration.png' wrapped ui={false} />
          <Card.Content>
            <div className='ui two buttons'>
              <Button basic color='black' href='existingInitiation'>
                Use Existing
              </Button>
              <Button basic color='green' href='createInitiation'>
                Create New
              </Button>
            </div>
          </Card.Content>
        </Card>

        <Card className='cardShadow'>
          <Card.Content>
            <Card.Header>Conflict Checks</Card.Header>
          </Card.Content>
          <Image
            src='/assets/dblue_CS_Project Management.png'
            wrapped
            ui={false}
          />
          <Card.Content>
            <div className='ui two buttons'>
              <Button basic color='black' href='/conflicts'>
                View All
              </Button>
              <Button basic color='green' href='createConflict'>
                Create New
              </Button>
            </div>
          </Card.Content>
        </Card>

        <Card className='cardShadow'>
          <Card.Content>
            <Card.Header>SOP Document</Card.Header>
          </Card.Content>
          <Image src='/assets/dblue_Brochure.png' wrapped ui={false} />
          <Card.Content>
            <div className='ui two buttons'>
              <Button
                basic
                color='black'
                href='https://projectresources.collierseng.com/docs/ConflictFormSOP.pdf'
              >
                Open SOP
              </Button>
            </div>
          </Card.Content>
        </Card>

        
          <Card className='cardShadow'>
          <Card.Content>
            <Card.Header>Project Search</Card.Header>

          </Card.Content>
          <Image src='/assets/dblue_Brochure.png' wrapped ui={false} />
          <Card.Content>
            <div className='ui two buttons'>
              <Button
                  basic
                  color='black'
                  href='/projectsearch'  
                >
                  Open Search
              </Button>
            </div>
          </Card.Content>
        </Card>
        <Card className='cardShadow'>
						<Card.Content>
							<Card.Header>Digital Signature</Card.Header>
						</Card.Content>
						<Image src='/assets/signature.png' wrapped ui={false} height='428'/>
						<Card.Content>
							<div className='ui two buttons'>
								<Button className='createBtn' basic color='green' href='/new-digital-signature'>New Request</Button>
							</div>
						</Card.Content>
				</Card>

       
        </Card.Group>
    </>
  );
};

export default Home;
