import React from "react";
import { useField, Field } from "formik";
import { Form, Label } from "semantic-ui-react";
import { StringLiteral } from "typescript";

interface Props {
  placeholder: string;
  name: string;
  className?:string;
  label?: string;
  type?: string;
  min?: number;
  disabled?: boolean;
  readonly?: string;
  as?: string;
  
  
}

export default function TextInputFormik(props: Props) {

  const [field, meta] = useField(props.name);

  return (
    <Form.Field error={meta.touched && !!meta.error}>
      
      <label>{props.label}</label>
      <input {...field} {...props} />
      {meta.touched && meta.error ? (
        <Label basic color='red'>{meta.error}</Label>
      ) : null}
    </Form.Field>
  )

}
