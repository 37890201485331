import React, { useContext, useEffect } from "react";
import { Grid, Segment, Button, Header, Icon , Table} from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { RouteComponentProps } from "react-router-dom";
import { Link } from "react-router-dom";

import LoadingComponent from "../../../app/layout/LoadingComponent";
import { RootStoreContext } from "../../../app/stores/rootStore";
import PageTitle from "../../../app/common/form/PageTitle";
import {format} from 'date-fns';
import {IProjectSearch} from "../../../app/models/projectSearch";
import "../../../app/layout/formStyles.css";

interface DetailParams {
    code: string;
}

const ProjectDetails: React.FC<RouteComponentProps<DetailParams>> = ({
    match,
}) => {
    const rootStore = useContext(RootStoreContext);
    
    
    const { project, loadingInitial, loadProject} = rootStore.projectStore;

    useEffect(() => {
        loadProject(match.params.code);
    }, [loadProject, match.params.code])


    if (!project || loadingInitial)
    return <LoadingComponent content="Loading project ..." />;

    if (!project) return <h2>Project Not Found</h2>;

    return (
        <>
            <div className='noPrint'>
                <PageTitle title="Project Details" />
            </div>
            <Segment clearing>
            <Grid columns={2} stackable>
                <Grid.Column>
                    <Table compact basic='very'>
                        <Table.Body>
                            <Table.Row>
                            <Table.Cell>Project Name</Table.Cell>
                            <Table.Cell className='bold' textAlign="right">{project.projectName}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                            <Table.Cell>Project Code</Table.Cell>
                            <Table.Cell className='bold' textAlign='right'>{project.projectCode}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                            <Table.Cell>Project Manager</Table.Cell>
                            <Table.Cell className='bold' textAlign='right'>{project.projectManager}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                            <Table.Cell>Client</Table.Cell>
                            <Table.Cell className='bold' textAlign='right'>{project.clientName}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Status</Table.Cell>
                                <Table.Cell className='bold' textAlign='right'>
                                {
                                    project.projectStatus == '0' ? <>Active</> : project.projectStatus == '1' ? <>Inactive</> : <>Closed</>
                                }
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Chargeable</Table.Cell>
                                <Table.Cell className='bold' textAlign='right'>
                                    {
                                        project.hasChargeable == '1' ? <><Icon name='check' color='green'/></> : project.hasChargeable == '0' ? <Icon name='x' color='red'/> : ''
                                    }
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Non Chargeable</Table.Cell>
                                <Table.Cell className='bold' textAlign='right'>
                                    {
                                        project.hasNonchargeable == '1' ? <><Icon name='check' color='green'/></> : project.hasNonchargeable == '0' ? <Icon name='x' color='red'/> : ''
                                    }
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>    
                    </Table>    
                </Grid.Column>
                <Grid.Column>
                    <Table compact basic='very'>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>Country</Table.Cell>
                                <Table.Cell className='bold' textAlign='right'>{project.country}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Address</Table.Cell>
                                <Table.Cell className='bold' textAlign='right'>{project.street}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>City</Table.Cell>
                                <Table.Cell className='bold' textAlign='right'>{project.city}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>State</Table.Cell>
                                <Table.Cell className='bold' textAlign='right'>{project.state}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>County</Table.Cell>
                                <Table.Cell className='bold' textAlign='right'>{project.county}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Block</Table.Cell>
                                <Table.Cell className='bold' textAlign='right'>{project.block}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Lot</Table.Cell>
                                <Table.Cell className='bold' textAlign='right'>{project.lot}</Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </Grid.Column>
            </Grid>
            {
                project.description ? 
                <Grid stackable>
                <Grid.Column>
                    
                    <div id='projectDesc'>Description:<br /> <b>{project.description}</b></div>
                    
                </Grid.Column>
                </Grid>
                : ''
            }
            <div id='buttons'>
            <Button
                as={Link}
                to={`/projectsearch`}
                floated="right"
                content="Back"
                color="blue"
                className='noPrint'
            />
            <Button className='noPrint' color="blue" floated="right" type="button" content="Print" onClick={() => {window.print()}}/>
            </div>
            
            
            </Segment>
        </>
    )
};

export default observer(ProjectDetails);