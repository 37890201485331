import React, { Fragment, useContext } from "react";
import { observer } from 'mobx-react-lite';
import { Item, Grid, Segment } from "semantic-ui-react";

import { RootStoreContext } from "../../../app/stores/rootStore";
import ConflictListItem from "./ConflictListItem";

const ConflictList: React.FC = () => {

  const rootStore = useContext(RootStoreContext);
  const { conflictsByDefault } = rootStore.conflictStore;
  //console.log(conflictsByDefault);
  //
    return (

          <Grid columns={2} stackable>
            <Fragment>
              {conflictsByDefault.map((conflict) => (
                < Grid.Column key={conflict.id}>
                  <Segment clearing>

                    <Item.Group>
                      <ConflictListItem key={conflict.id} conflict={conflict} />
                    </Item.Group>

                  </Segment>
                </Grid.Column>
              ))}
            </Fragment>

          </Grid>
    );
};

export default observer(ConflictList);
