import React, { SyntheticEvent, useContext, useState } from "react";
import { observer } from "mobx-react-lite";
import { Button, DropdownOnSearchChangeData, DropdownProps, Select, Input, Form, Grid, Loader, Modal, Segment, Table, Label } from "semantic-ui-react";

import './DigitalSignatureForm.css';
import { RootStoreContext } from "../../app/stores/rootStore";
import PageTitle from "../../app/common/form/PageTitle";
import ConfirmCancelForm from "../modals/ConfirmCancelForm";
import { DigitalSignatureFormValues, IDigitalSignature,  } from "../../app/models/digitalSignature";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from 'yup';

const rate_initial = 300.00;
const rate_eachadditionalpage = 75.00;


const validationSchema = Yup.object({
    project: Yup.string().required('Project is required.'),
    sheetCount: Yup.number().required('Sheet Count is required.').min(1,"Sheet count must be greater than zero."),
    
});

const DigitalSignatureForm = () => {
    const rootStore = useContext(RootStoreContext);
    const { createDigitalSignatureForm, submitting, searchProjects, loadTasks, projectList, taskList } = rootStore.digitalSignatureStore;
	const { openModal } = rootStore.modalStore;
    const { accountInfoUserName } = rootStore.userStore;
    
    const [digiSignForm, setDigiSignForm] = useState(new DigitalSignatureFormValues());
    const [loading, setLoading] = useState(false);
    const [reviewFormOpen, setReviewFormOpen] = useState(false);
    
    const handleProjectSearch = (event: SyntheticEvent<HTMLElement, Event>, data: DropdownOnSearchChangeData) => {
        if (data.searchQuery.length >= 3) {
            setLoading(true);
            searchProjects(data.searchQuery).then(() => { setLoading(false); });
        };
    };

    const handleProjectChange = ((event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
        setDigiSignForm({...digiSignForm, project: data.value as string});
        loadTasks(data.value as string);
    });


    const handleTaskChange = ((event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps, values: DigitalSignatureFormValues) => {
        const { ...digiSignForm} = values;
        setDigiSignForm({...digiSignForm, task: data.value as string});
    });


    const handleCommentChange = ((event: React.SyntheticEvent<HTMLElement, Event>, values: DigitalSignatureFormValues) => {
        setDigiSignForm({...digiSignForm, comments: values.comments});
    });

    const handleSheetChange = ((event: React.SyntheticEvent<HTMLElement, Event>, values: DigitalSignatureFormValues, num: any) => {
        const { ...digiSignForm} = values;
        setDigiSignForm({...digiSignForm, sheetCount: values.sheetCount});
        let convertedNum = num || 0; //in case of empty field, sanitize NaN to 0.
        let cost = '';
        cost.replace('NaN','0');
        if (isNaN(convertedNum) == true)  {
            cost = "$0.00";
        }
        else {
            cost = "$" + (rate_initial + rate_eachadditionalpage * (convertedNum - 1)) + ".00";
            
        } 
        setDigiSignForm({...digiSignForm, sheetCount: num, totalCost: cost});
        
    });

    const handleCostChange = ((event: React.SyntheticEvent<HTMLElement, Event>, values: DigitalSignatureFormValues ) => {
        const {...digiSignForm} = values;
        let cost = '';
        cost.replace('NaN','0');
        if (isNaN(values.sheetCount) == true)  {
            cost = "$0.00";
        }
        else {
            cost = "$" + (rate_initial + rate_eachadditionalpage * (values.sheetCount - 1)) + ".00";
        } 
        setDigiSignForm({...digiSignForm, totalCost: cost});
        
        
    });

    const submitPayload = (values: IDigitalSignature) => {
        if (values.task === '' || values.task === undefined)
            values.task = 'ZREIMB';
        if (values.comments === undefined)
            values.comments = "";

        values.requester = accountInfoUserName!;
        //console.log('sending:' + JSON.stringify(values));
        createDigitalSignatureForm(values);
    }

    const handleFormSubmit = (values: DigitalSignatureFormValues) => {
        console.log("submitting values");
        const {...digiSignForm} = values;
        console.log("sending: " + JSON.stringify(digiSignForm));
    }

    return (
        <>
            <PageTitle title="Digital Signature Payment Request" />
            <Segment>
                <Loader content="Loading Projects..." active={loading}/>
                <Formik
                    enableReinitialize={true}
                    onSubmit={handleFormSubmit}
                    initialValues={digiSignForm}
                    validationSchema={validationSchema}
                >
                {({values, setFieldTouched, handleChange, errors, isValid, touched, isSubmitting}) => (
                    <Form className='ui form'>
                        <Grid stackable columns={2}>
                                <Grid.Row>
                                    <Grid.Column width={8}>
                                    <label>Project</label>
                            <Form.Field className='fieldDiv'>
                                <Select placeholder='Search projects'
                                search
                                fluid
                                value={values.project}
                                name='project'
                                className="formField"
                                onChange={handleProjectChange}
                                onSearchChange={handleProjectSearch}
                                options={
                                    projectList.map((p) => ({
                                    key: p.title,
                                    value: p.title,
                                    text: p.title,
                                }))}
                                
                                onBlur={() => {setFieldTouched('project')}}
                            />
                            </Form.Field>
                            <span className="inputSubtext">
                                Type at least 3 characters to search.
                            </span>
                            </Grid.Column>
                            <Grid.Column width={8}>
                            <label>Task</label>
                            <Form.Field className='fieldDiv'>
                                <Select placeholder='Select task'
                                
                                fluid
                                value={values.task}
                                name='task'
                                className="formField"
                                onSearchChange={handleProjectChange}
                                options={
                                    taskList.map((p) => ({
                                        key: p.taskCode,
                                        value: p.taskCode,
                                        text: p.taskCode,
                                }))}
                                onChange={(e,v) => {
                                    handleTaskChange
                            (e,v,values);
                                    setTimeout(() => {
                                        setFieldTouched('task');
                                      }) 
                                }}
                                onBlur={() => {setFieldTouched('task')}}
                                />
                                </Form.Field>
                                <span className="inputSubtext">
                                    If Task is blank, it will default to ZREIMB.
                                </span>
                                </Grid.Column>

                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={8}>
                                    <label>Number of Sheets with Signature</label>
                                    
                                    <Input
                                        className='sheetCount'
                                        value={values.sheetCount}
                                        name='sheetCount'
                                        placeholder="Sheet count"
                                        type="number"
                                        min={1}
                                        onChange={(e) => {
                                            //handleChange(e);
                                            let n = parseInt(e.target.value);
                                            handleSheetChange(e, values, n);
                                            setTimeout(() => {
                                                setFieldTouched('sheetCount');
                                            }) 

                                        }}
                                        onBlur={(e:React.SyntheticEvent<HTMLElement, Event>) => {
                                            handleCostChange(e,values);
                                            setTimeout(() => {
                                                setFieldTouched('sheetCount');
                                            }) 
                                        }} 
                                    />
                                    
                                    
                                </Grid.Column>
                                <Grid.Column width={8}>
                                    <label>Total Cost</label>
                                    
                                    <Field
                                        name="totalCost"
                                        className="totalCost"
                                        placeholder="cost"
                                        readonly="readonly"
                                    />
                                    
                                </Grid.Column>
                                
                            </Grid.Row>
                            <Grid.Row>
                                    <Grid.Column width={16}>
                                        <label>Comments</label>
                                        
                                        
                                        <Field
                                            name='comments'
                                            className='comments'
                                            placeholder='Comments'
                                            as='textarea'
                                            value={values.comments}
                                            onChange={handleChange}
                                            onBlur={(e: React.SyntheticEvent<HTMLElement, Event>) => {
                                                handleCommentChange(e, values);
                                                setTimeout(() => {
                                                    setFieldTouched('comments');
                                                }) 
                                            }}
                                        />

                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                    <Grid.Column width={16}>
                                        <Button
                                            content="Cancel"
                                            type="button"
                                            color='grey'
                                            onClick={() => openModal(<ConfirmCancelForm />)}
                                        />
                                        <Button
                                            content="Submit"
                                            floated="right"
                                            positive
                                            disabled={isSubmitting || !isValid || (Object.keys(touched).length === 0 && touched.constructor === Object)}
                                            onClick={() => setReviewFormOpen(true)}
                                        />
                                    </Grid.Column>
                                </Grid.Row>

                        </Grid>
                        <Modal open={reviewFormOpen} onClose={() => setReviewFormOpen(false)}>
                                <Modal.Header>Review Submission</Modal.Header>
                                <Modal.Content>
                                    <p>Please take a moment to review your request before you submit it.</p>
                                    <Table basic='very'>
                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Cell width={10}><b>Project Number</b></Table.Cell>
                                                <Table.Cell width={6}>{values.project}</Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell width={10}><b>Task</b></Table.Cell>
                                                <Table.Cell width={6}>{values.task !== '' && values.task !== undefined ? values.task : "ZREIMB"}</Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell width={10}><b>Number of Sheets with Signature</b></Table.Cell>
                                                <Table.Cell width={6}>{values.sheetCount}</Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell width={10}><b>Total Cost</b></Table.Cell>
                                                <Table.Cell width={6}>{values.totalCost}</Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell width={10}><b>Comments</b></Table.Cell>
                                                <Table.Cell width={6}>{values.comments}</Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button color="grey" onClick={() => setReviewFormOpen(false)} content='Back'/>
                                    <Button
                                        type='submit'
                                        content='Submit'
                                        loading={submitting}
                                        color="green"
                                        floated="right"
                                        onClick={() => {submitPayload(values);}}
                                    />
                                </Modal.Actions>
                            </Modal>
                    </Form>
                )}
                </Formik>
            </Segment>
        </>
    )
};

export default observer(DigitalSignatureForm);