

export interface IProjectSearch {

    projectName: string;
    projectManager: string;
    //description: string;
    client: string;
    street: string;
    projectCode: string;
    block: string;
    lot: string;
    chargeableYes: boolean; 
    chargeableNo: boolean;
    nonchargeableYes: boolean;
    nonchargeableNo: boolean;
    statusActive: boolean;
    statusInactive: boolean;
    statusClosed: boolean;
    city: string;
    state: string;
    county: string;
    country: string;
    description: string;
    clientName: string;
    projectStatus: string;
    projectOrg: string;
    hasChargeable: string;
    hasNonchargeable: string;

    
}

export interface IProjectEnvelope {
    projects: IProjectSearch[];
}


export class ProjectSearchFormValues implements IProjectSearch {
    projectName: string = '';
    projectManager: string = '';
    client: string = '';
    street: string = '';
    projectCode: string = '';
    block: string = '';
    lot: string = '';
    chargeableYes: boolean = true;
    chargeableNo: boolean = false;
    nonchargeableYes: boolean = false;
    nonchargeableNo: boolean = false;
    statusActive: boolean = true;
    statusInactive: boolean = false;
    statusClosed: boolean = false;
    city: string = '';
    state: string = '';
    county: string = '';
    country: string = '';
    description: string = '';
    id: number = 0;

    projectStatus: string = '';
    projectOrg: string = '';
    clientName: string = '';
    hasChargeable: string = '';
    hasNonchargeable: string = '';



    constructor(init?: ProjectSearchFormValues) {
        Object.assign(this,init);
    }

}