import { observable, action, runInAction, computed } from "mobx";
import { toast } from "react-toastify";
import { history } from "../..";
import { RootStore } from "./rootStore";
import agent from "../api/agent";

import { IManager } from "../models/manager";
import { IState } from "../models/state";
import { IClientType } from "../models/clientType";
import { IProjectSearch, IProjectEnvelope } from "../models/projectSearch";
import ConflictDashboard from "../../features/conflict/dashboard/ConflictDashboard";
import ProjectListItem from "../../features/conflict/dashboard/ProjectListItem";

const PAGE_SIZE = 10;

export default class ProjectStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    @observable project: IProjectSearch | null = null;
    
    
    @observable states: IState[] = [];

    @observable projectRegistry = new Map();
    @observable submitting = false;
    @observable loadingInitial = false;
    
    
    
    @observable projectCount: number = 0;
    @observable page = 0;
    @observable predicate = new Map<string, string | number | boolean>();
    

    
    

    /*************************************
   *
   *************************************/
  
    @action setCount = (count: number) => {
        this.projectCount = count;
    };

    /*************************************
   *
   *************************************/

    @computed get totalPages() {
        return Math.ceil(this.projectCount / PAGE_SIZE);
    }

    /*************************************
   *
   *************************************/
  @action addProject = (project: IProjectSearch) => {
    this.projectRegistry.set(project.projectCode, project);
  }






    /*************************************
   *
   *************************************/

    @action setPage = (page: number) => {
        this.page = page;
    }
    
    /*************************************
   *
   *************************************/

    get projectsLength() {
        return this.projectRegistry.size;
    }

    /*************************************
   *
   *************************************/

    @action setPredicate = (
        predicate: string,
        value: string | number | boolean
    ) => {
        if (predicate !== "all") {
            this.predicate.set(predicate, value);
        }
    };


    @action getPredicate = () => {
        return this.predicate;
    }

    /*************************************
   *
   *************************************/

    @computed get axiosParams() {
        const params = new URLSearchParams();
        params.append("pageNumber", `${this.page}`);
        params.append("pageSize", String(PAGE_SIZE));
    
        this.predicate.forEach((value: string | number | boolean, key: string) => {
          params.append(key, value.toString());
        });
    
        return params;
    }

    /*************************************
   *
   *************************************/

    @action clearPredicate = () => {
        this.predicate.clear();
    };
    
    @action removePredicate = (key: string) => {
        this.predicate.delete(key);
    };

    @action clearProjectRegistry = () => {
        this.projectRegistry.clear();
        this.page = 0;
    };

    @computed get projectsByDefault() {
        return Array.from(this.projectRegistry.values()
        );
    }


    @action loadProjects = async () => {
        console.log("loading projects...");
        this.loadingInitial = true;

        try {
            
            const response = await agent.Project.list(this.axiosParams);
            
            let pagination = JSON.parse(response.headers.pagination);
            let count = pagination.totalItems; 

            
            this.setCount(count);

            runInAction ("loading projects", () => {
                
                response.data.projects.forEach((project: IProjectSearch) => {
                    
                    this.projectRegistry.set(project.projectCode, project);
                   
                });
                this.loadingInitial = false;
            });

            
        } catch (error) {
            runInAction("load projects error", () => {
              this.loadingInitial = false;
            });     
            //console.log(error);
        }
       
    };


    @action getProject = (code: string) => {
        return this.projectRegistry.get(code);
    }

    @action loadProject = async (code: string) => {
        
        let project = this.getProject(code);
        if (project) {
            this.project = project;
            return project;
        } 
        else {
            
            try {
                const response = await agent.Project.details(code);

                
                runInAction("getting project", () => {
                    project = response.project; 

                    this.project = project!;
                    this.projectRegistry.set(project.projectCode, project);
                    this.loadingInitial = false;
                    return project;

                })
            }
            catch (error) {
                runInAction("get project error", () => {
                    this.loadingInitial = false;
                });
                console.log(error);
            }
        }
    }

}

