import React, { useContext } from "react";
import { Button, Grid, Segment, Header } from "semantic-ui-react";
import { RootStoreContext } from "../../app/stores/rootStore";

import { history } from "../..";

const ConfirmCancelForm = () => {
  const rootStore = useContext(RootStoreContext);
  const {
    closeModal,
  } = rootStore.modalStore;

  return (
    <Segment clearing>
      <Grid columns={2} textAlign='center'>
        <Header as='h3'>
          <br/>'Yes' will clear and close this form.
        </Header>
        <Grid.Column>
            <Button color='red' onClick={() => closeModal()} content="No"/>
        </Grid.Column>
        <Grid.Column>
            <Button color="green" onClick={() => {history.push('/'); closeModal();}} content='Yes' />
        </Grid.Column>
      </Grid>
    </Segment>
  );
};

export default ConfirmCancelForm;
