import React, { useContext, useState, useEffect } from "react";
import { Header, Button, Form, Grid, Segment } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { Form as FinalForm, Field } from "react-final-form";
import { combineValidators, isRequired } from "revalidate";
import { RouteComponentProps } from "react-router-dom";
import arrayMutators from "final-form-arrays";

import { RootStoreContext } from "../../../app/stores/rootStore";
import PageTitle from "../../../app/common/form/PageTitle";
import CheckboxInput from "../../../app/common/form/CheckboxInput";
import TextInput from "../../../app/common/form/TextInput";
import TextAreaInput from "../../../app/common/form/TextAreaInput";
import SelectInput from "../../../app/common/form/SelectInput";
import { IConflictFormValues } from "../../../app/models/conflict";

const appointedOptions = [
  { key: "0", value: 0, text: "Unknown" },
  { key: "1", value: 1, text: "Yes" },
  { key: "2", value: 2, text: "No" },
];

const validate = combineValidators({
  projectManagerName: isRequired("Project Manager Name"),
  projectName: isRequired("Project Name"),
  company: isRequired("Company"),
  companyContact: isRequired("Company Contact"),
  location: isRequired("Location"),
  clientType: isRequired("Client Type"),
  state: isRequired("State"),
  appointed: isRequired("Appointed"),
  description: isRequired("Description"),
});

interface DetailParams {
  id: string;
}

const ConflictForm: React.FC<RouteComponentProps<DetailParams>> = ({
  match,
}) => {
  const rootStore = useContext(RootStoreContext);
  const { accountInfoUserName, accountInfoUserEmail } = rootStore.userStore;
  const {
    createConflict,
    loadConflict,
    editConflict,
    submitting,
    loadNewConflictInfo,
    states,
    managers,
    clientTypes,
  } = rootStore.conflictStore;

  const [conflict, setConflict] = useState(new IConflictFormValues());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (match.params.id) {
      setLoading(true);
      loadConflict(parseInt(match.params.id))
        .then((conflict: IConflictFormValues) => {
          let tempConflict = new IConflictFormValues(conflict);
          tempConflict.projectManagerName = conflict.projectManagerName + "|" + conflict.projectManagerEmail;
          setConflict(new IConflictFormValues(tempConflict));
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(true);
      loadNewConflictInfo().finally(() => setLoading(false));
    }
  }, [loadNewConflictInfo, loadConflict, match.params.id]);

  const setManagerEmail = (event: React.SyntheticEvent, data: any) => {
    console.log(data.value);
    let mngr = managers.filter((manager) => manager.managerName === data.value);
    console.log(mngr[0].managerEmail);
    conflict.projectManagerEmail = mngr[0].managerEmail;
  };

  const handleFinalFormSubmit = (values: IConflictFormValues) => {
    console.log("submitting");
    const { ...conflict } = values;

    // extract email from name
    const [managerName, managerEmail] = values.projectManagerName.split("|");
    conflict.projectManagerName = managerName;
    conflict.projectManagerEmail = managerEmail;

    if (conflict.hasRailroadComponent) conflict.hasRailroadComponent = true;
    else conflict.hasRailroadComponent = false;

    // create report or or edit exisitng
    if (!conflict.id) {
      conflict.createDate = new Date(Date.now());

      conflict.createdByName = accountInfoUserName
        ? accountInfoUserName
        : "error: no name loaded in client";

      conflict.createdByEmail = accountInfoUserEmail
        ? accountInfoUserEmail
        : "error: no email loaded in client";

      // console.log(conflict);
      console.log("creating conflict");
      createConflict(conflict);
    } else {
      console.log("editing conflict...");
      editConflict(conflict);
    }
  };

  return (
    <>
      <PageTitle title="Conflict Check Form" />
      <Grid>
        <Grid.Column width={16}>
          <Segment clearing>
            <FinalForm
              validate={validate}
              initialValues={conflict}
              onSubmit={handleFinalFormSubmit}
              mutators={{ ...arrayMutators }}
              // loading={loading}
              render={({
                handleSubmit,
                invalid,
                // pristine,
                // // form,
                // values
              }) => (
                <Form onSubmit={handleSubmit} loading={loading}>
                  <Header as="h3">Create Conflict Check Form</Header>
                  Please{" "}

                  <a
                    href="https://projectresources.collierseng.com/docs/ConflictFormSOP.pdf"
                    target="blank"
                  >
{/*
                  <a
                    href="https://maserc.sharepoint.com/sites/cehub/Shared%20Documents/ConflictFormSOP.pdf"
                    target="blank"
                  >
*/}                  
                    read the SOP
                  </a>{" "}
                  for important changes to the operation of the Conflict Check
                  Form.
                  <Segment>
                    <Form.Group widths="equal">
                      <Field
                        name="projectManagerName"
                        placeholder="Project Manager Name"
                        value={conflict.projectManagerName}
                        search
                        options={managers.map((m) => ({
                          key: m.managerEmail,
                          value: m.managerName + "|" + m.managerEmail,
                          text: m.managerName,
                        }))}
                        onChange={setManagerEmail}
                        component={SelectInput}
                      />
                      <Field
                        component={TextInput}
                        // width={5}
                        name="projectName"
                        placeholder="Project Name"
                        value={conflict.projectName}
                      
                      />
                    </Form.Group>
                    <Form.Group>
                      <Field
                        component={TextInput}
                        width={8}
                        name="companyContact"
                        placeholder="Company Contact (External)"
                        value={conflict.company}
                      />
                      <Field
                        component={TextInput}
                        width={8}
                        name="company"
                        placeholder="Company"
                        value={conflict.company}
                      />
                    </Form.Group>

                    <Form.Group>
                      <Field
                        component={SelectInput}
                        width={4}
                        name="clientType"
                        placeholder="Client Type"
                        options={clientTypes.map((c) => ({
                          key: c.id,
                          value: c.id,
                          text: c.clientTypeName,
                        }))}
                        value={conflict.clientType.toString()}
                      />

                      <Field
                        component={SelectInput}
                        width={4}
                        name="appointed"
                        placeholder="Appointed"
                        options={appointedOptions}
                        value={conflict.appointed.toString()}
                      />
                      <Field className="checkboxPadding"
                        component={CheckboxInput}
                        width={4}
                        label="Confidential"
                        name="confidential"
                        type='checkbox'
                        />
                      <Field
                        component={CheckboxInput}
                        width={4}
                        label="Has Railroad Component"
                        name="hasRailroadComponent"
                        type='checkbox'
                      />
                    </Form.Group>

                    <Form.Group widths="equal">
                      <Field
                        component={TextInput}
                        // width={11}
                        name="location"
                        placeholder="Location"
                        value={conflict.location}
                      />
                      <Field
                        name="state"
                        placeholder="State"
                        value={conflict.state.toString()}
                        search
                        width={5}
                        options={states.map((s) => ({
                          key: s.id,
                          value: s.id,
                          text: s.stateName,
                        }))}
                        component={SelectInput}
                      />
                    </Form.Group>

                    <Field
                      component={TextAreaInput}
                      rows={4}
                      name="description"
                      placeholder="Description"
                      value={conflict.description}
                    />
                  </Segment>
                  <Button
                    loading={submitting}
                    disabled={loading || invalid}
                    floated="right"
                    positive
                    type="submit"
                    content="Submit"
                  />
                </Form>
              )}
            />
          </Segment>
        </Grid.Column>
      </Grid>
    </>
  );
};

export default observer(ConflictForm);
