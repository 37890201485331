import React, { Fragment, useContext } from "react";
import { observer } from 'mobx-react-lite';
import { Item, Grid, Segment } from "semantic-ui-react";

import { RootStoreContext } from "../../../app/stores/rootStore";
import InitiationListItem from "./InitiationListItem";

const InitiationList: React.FC = () => {

  const rootStore = useContext(RootStoreContext);
  const { initiationsByDefault } = rootStore.initiationStore;

    return (

          <Grid columns={2} stackable>
            <Fragment>
              {initiationsByDefault.map((initiation) => (
                < Grid.Column key={initiation.id}>
                  <Segment clearing>

                    <Item.Group>
                      <InitiationListItem key={initiation.id} initiation={initiation} />
                    </Item.Group>

                  </Segment>
                </Grid.Column>
              ))}
            </Fragment>

          </Grid>
    );
};

export default observer(InitiationList);
