import { observable, action, runInAction, computed } from "mobx";
import { toast } from "react-toastify";
import { history } from "../..";
import { RootStore } from "./rootStore";
import agent from "../api/agent";
import { IConflict } from "../models/conflict";
import { IManager } from "../models/manager";
import { IState } from "../models/state";
import { IClientType } from "../models/clientType";

const PAGE_SIZE = 10;

export default class ConflictStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable conflict: IConflict | null = null;
  //@observable conflictRegistry = new Map<number, IConflict>();
  @observable conflictRegistry = new Map();
  @observable submitting = false;
  @observable loadingInitial = false;

  @observable managers: IManager[] = [];
  @observable clientTypes: IClientType[] = [];
  @observable states: IState[] = [];

  @observable responseFormVisible: boolean = false;

  @observable conflictCount: number = 0;
  @observable page = 0;

  @observable predicate = new Map<string, string | number | boolean>();

  /*************************************
   *
   *************************************/

  @action setCount = (count: number) => {
    this.conflictCount = count;
  };

  /*************************************
   *
   *************************************/

  @action setResponseFormVisible = (value: boolean) => {
    this.responseFormVisible = value;
  };

  /*************************************
   *
   *************************************/

  @computed get totalPages() {
    //console.log("count: " + this.conflictCount);
    //console.log("PAGE_SIZE: " + PAGE_SIZE);

    return Math.ceil(this.conflictCount / PAGE_SIZE);
  }

  /*************************************
   *
   *************************************/

  @action setPage = (page: number) => {
    this.page = page;
  };

  /*************************************
   *
   *************************************/

  get conflictsLength() {
    return this.conflictRegistry.size;
  }

  /*************************************
   *
   *************************************/

  @action setPredicate = (
    predicate: string,
    value: string | number | boolean
  ) => {
    if (predicate !== "all") {
      this.predicate.set(predicate, value);
    }
  };

  /*************************************
   *
   *************************************/

  @computed get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", `${this.page}`);
    params.append("pageSize", String(PAGE_SIZE));

    this.predicate.forEach((value: string | number | boolean, key: string) => {
      params.append(key, value.toString());
    });

    return params;
  }

  @action clearPredicate = () => {
    this.predicate.clear();
  };

  @action removePredicate = (key: string) => {
    this.predicate.delete(key);
  };

  @action clearConflictRegistry = () => {
    // console.log(this.conflictRegistry);
    this.conflictRegistry.clear();
    this.page = 0;
  };

  @computed get conflictsByDefault() {
    return Array.from(this.conflictRegistry.values()).sort(
      (a, b) =>
        Date.parse(b.createDate.toString()) -
        Date.parse(a.createDate.toString())
    ); // sort in descending order
  }

  /*************************************
   *
   *************************************/

  @computed get conflictsByDate() {
    return this.groupConflictsByDate(
      Array.from(this.conflictRegistry.values())
    );
  }

  /*************************************
   *
   *************************************/

  groupConflictsByDate(conflicts: IConflict[]) {
    const sortedConflicts = conflicts.sort(
      (a, b) =>
        Date.parse(b.createDate.toString()) -
        Date.parse(a.createDate.toString())
    );

    return Object.entries(
      sortedConflicts.reduce((conflicts, conflict) => {
        const date = new Date(conflict.createDate.toString()).toDateString();
        conflicts[date] = conflicts[date]
          ? [...conflicts[date], conflict]
          : [conflict];
        return conflicts;
      }, {} as { [key: string]: IConflict[] })
    );
  }

  /*************************************
   *
   *************************************/

  @computed get conflictsByManager() {
    // console.log('registry.values: ' + this.vehicleAccidentReportRegistry.values());
    return this.groupConflictsByManager(
      Array.from(this.conflictRegistry.values())
    );
  }

  /*************************************
   *
   *************************************/

  groupConflictsByManager(conflicts: IConflict[]) {
    return Object.entries(
      conflicts.reduce((conflicts, conflict) => {
        const name = conflict.projectManagerName;
        conflicts[name] = conflicts[name]
          ? [...conflicts[name], conflict]
          : [conflict];
        return conflicts;
      }, {} as { [key: string]: IConflict[] })
    );
  }

  /*************************************
   *
   *************************************/

  @action loadNewConflictInfo = async () => {
    console.log("loading new conflict info...");
    this.loadingInitial = true;

    try {
      const response = await agent.Conflict.newConflictInfo();
      // console.log(response);

      runInAction("loading new conflict info", () => {
        this.managers = response.managersToReturn;
        this.states = response.states;
        this.clientTypes = response.clientTypes;

        this.loadingInitial = false;
      });
    } catch (error) {
      runInAction("load new conflict info error", () => {
        this.loadingInitial = false;
      });
      console.error(error);
    }
  };

  /*************************************
   *
   *************************************/

  @action loadConflicts = async () => {
    console.log("loading conflicts...");
    this.loadingInitial = true;
    

    try {
      const response = await agent.Conflict.list(this.axiosParams);
      //console.log(response.data.conflicts);
      let pagination = JSON.parse(response.headers.pagination);
      let count = pagination.totalItems;
      this.setCount(count);

      
      runInAction("loading conflicts", () => {

        //this.managers = response.managersToReturn;
        //this.states = response.states;
        //this.clientTypes = response.clientTypes;

        //const conflicts: IConflict[] = response.conflicts;
        this.managers = response.data.managersToReturn;
        this.states = response.data.states;
        this.clientTypes = response.data.clientTypes;

        response.data.conflicts.forEach((conflict: IConflict) => {
        //conflicts.forEach((conflict: IConflict) => {
          //console.log('conflict: ' + JSON.stringify(conflict))

          const cDate = new Date(conflict.createDate);
          conflict.createDate = new Date(
            cDate.getTime() - cDate.getTimezoneOffset() * 60000
          );

          this.conflictRegistry.set(conflict.id, conflict);
        });

        //console.log(this.conflictRegistry);
        this.loadingInitial = false;
      });
    } catch (error) {
      runInAction("load conflicts error", () => {
        this.loadingInitial = false;
      });

      //console.log(error);
    }
  };

  /*************************************
   *
   *************************************/

  getConflict = (id: number) => {
    // console.log(this.vehicleAccidentReportRegistry.get(id));
    return this.conflictRegistry.get(id);
  };

  /*************************************
   *
   *************************************/

  @action loadConflict = async (id: number) => {
    //this.loadNewConflictInfo();
    let conflict = this.getConflict(id);

    if (conflict) {
      //console.log(conflict);
      this.conflict = conflict;
      //this.conflict.createDate = new Date(conflict.createDate);
      return conflict;
    } else {
      this.loadingInitial = true;

      try {
        //let conflict = await agent.Conflict.details(id);
        const response = await agent.Conflict.details(id);

        runInAction("getting conflict", () => {

          this.managers = response.managersToReturn;
          this.states = response.states;
          this.clientTypes = response.clientTypes;

          conflict = response.conflict;
  
          //conflict.conflict.createDate = new Date(conflict.conflict.createDate);
          //this.conflict = conflict.conflict;[]

          const cDate = new Date(conflict.createDate);
          conflict.createDate = new Date(
            cDate.getTime() - cDate.getTimezoneOffset() * 60000
          );

          //conflict.createDate = new Date(conflict.createDate);

          this.conflict = conflict!;

          this.conflictRegistry.set(conflict.id, conflict);

          this.loadingInitial = false;
        });
        return conflict;
      } catch (error) {
        runInAction("get conflict error", () => {
          this.loadingInitial = false;
        });

        console.log(error);
      }
    }
  };

  /*************************************
   *
   *************************************/

  @action createConflict = async (conflict: IConflict) => {
    this.submitting = true;
    
    try {
      const response = await agent.Conflict.createConflict(conflict);

      runInAction("create conflict", () => {
        this.conflictRegistry.set(conflict.id, conflict);
        this.submitting = false;
      });

      history.push(`/`);
      toast.success("New Conflict Report Added");
    } catch (error: any) {
      runInAction("create conflict form error", () => {
        this.submitting = false;
      });

      toast.error("Problem submitting data");
      console.error(error.response);
    }
  };

  /*************************************
   *
   *************************************/

  @action addConflictResponse = async (conflict: IConflict) => {
    this.submitting = true;

    try {
      await agent.Conflict.addResponse(conflict.id, conflict);

      runInAction("create conflict response", () => {
        this.conflictRegistry.set(conflict.id, conflict);
        this.submitting = false;
      });

      history.push(`/`);
      //history.push(`/conflict/${conflict.id}`);
      toast.success("New Conflict Response Added");
    } catch (error: any) {
      runInAction("create conflict response error", () => {
        this.submitting = false;
      });

      toast.error("Problem submitting data");
      console.error(error.response);
    }
  };

  /*************************************
   *
   *************************************/

  @action editConflict = async (conflict: IConflict) => {
    this.submitting = true;

    try {
      console.log("submitting??");
      await agent.Conflict.edit(conflict);

      runInAction("editing conflict", () => {
        this.conflictRegistry.set(conflict.id, conflict);
        this.conflict = conflict;
        this.submitting = false;
      });

      history.push(``);
      toast.success("Conflict Report Updated");
    } catch (error: any) {
      runInAction("edit conflict error", () => {
        this.submitting = false;
      });

      toast.error("Problem submitting data");
      console.log(error.response);
    }
  };
}
