export interface IDigitalSignature {
    project: string;
    task: string;
    sheetCount: number;
    comments: string;
    requester: string;
}

export interface IDigitalSignatureFormValues extends IDigitalSignature {
}

export class DigitalSignatureFormValues implements IDigitalSignatureFormValues {
    project: string = "";
    task: string = "";
    sheetCount: number = 1;
    comments: string = "";
    totalCost: string = "$300.00";
    requester: string = "";

    constructor (init?: IDigitalSignatureFormValues) {
        Object.assign(this, init);
    }
}
