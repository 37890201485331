import React, { useContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Header, Label, Button, Form, Grid, Segment, CheckboxProps, Select, Dropdown, DropdownProps } from "semantic-ui-react";
import { observer, useForceUpdate } from "mobx-react-lite";
import { RouteComponentProps } from "react-router-dom";

import { ErrorMessage, Formik, useField, validateYupSchema, yupToFormErrors, Field } from "formik"
import * as Yup from 'yup';

import TextInputFormik from "../../../app/common/form/TextInputFormik";
import SelectInputFormik from "../../../app/common/form/SelectInputFormik";
import CheckboxNew from "../../../app/common/form/CheckboxNew";

import { RootStoreContext } from "../../../app/stores/rootStore";
import PageTitle from "../../../app/common/form/PageTitle";

import RenderIf from "../../../app/common/form/RenderIf";
import { ProjectSearchFormValues } from "../../../app/models/projectSearch";
import { ISuffix } from "../../../app/models/suffix";
import { is } from "date-fns/locale";
import "../../../app/layout/styles.css"

import ConflictSearch from "../dashboard/ConflictSearch";
import { remove } from "final-form-arrays";

const ProjectSearch = () => {

    const rootStore = useContext(RootStoreContext);
    const { states } = rootStore.conflictStore;
    

    const {
        clearPredicate, setPredicate, removePredicate,
        clearProjectRegistry, loadProjects, getPredicate,
    } = rootStore.projectStore;

    
    const [projectSearch, setProjectSearch] = useState(new ProjectSearchFormValues());

    const [moreVisible, setMoreVisible] = useState(false);
    


    const handleFormSubmit = (values: ProjectSearchFormValues) => {
        console.log("Submitting search");
        const {...projectSearch} = values;
        //console.log("sending: " + JSON.stringify(projectSearch));

        if (values.projectName !== '') {
            setPredicate('projectName', values.projectName);
        }
        else 
            removePredicate('projectName');

        if (values.projectManager !== '') {
            setPredicate('projectManager', values.projectManager);
        }
        else
            removePredicate('projectManager');

        if(values.client !== '') {
            setPredicate('client', values.client);
        }
        else
            removePredicate('client');

        if(values.street !== 'street') {
            setPredicate('street', values.street);
        }
        else
            removePredicate('street');

        if(values.projectCode !== 'projectCode') {
            setPredicate('projectCode', values.projectCode);
        }
        else
            removePredicate('projectCode');
            
        if(values.block !== 'block') {
            setPredicate('block', values.block);
        }
        else
            removePredicate('block');

        if(values.lot !== 'lot') {
            setPredicate('lot', values.lot);
        }
        else
            removePredicate('lot');

        if(values.chargeableYes !== null) {
            setPredicate('chargeableYes', values.chargeableYes);
        }
        else
            removePredicate('chargeableYes');
        
        if(values.chargeableNo !== null) {
            setPredicate('chargeableNo', values.chargeableNo);
        }
        else
            removePredicate('chargeableNo');

        if(values.nonchargeableYes !== null) {
            setPredicate('nonchargeableYes', values.nonchargeableYes);
        }
        else
            removePredicate('nonchargeableYes');

        if(values.nonchargeableNo !== null) {
            setPredicate('nonchargeableNo', values.nonchargeableNo);
        }
        else
            removePredicate('nonchargeableNo');
        
        if(values.statusActive !== null) {
            setPredicate('statusActive', values.statusActive);
        }
        else
            removePredicate('statusActive');

        if(values.statusInactive !== null) {
            setPredicate('statusInactive', values.statusInactive);
        }
        else
            removePredicate('statusInactive');

        if(values.statusClosed !== null) {
            setPredicate('statusClosed', values.statusClosed);
        }
        else
            removePredicate('statusClosed');

        if(values.city !== 'city') {
            setPredicate('city', values.city);
        }
        else
            removePredicate('city');
    
        if(values.state !== 'state') {
            setPredicate('state', values.state);
        }
        else
            removePredicate('state');

        if(values.county !== 'county') {
            setPredicate('county', values.county);
        }
        else
            removePredicate('county');
        
        if(values.country !== 'country') {
            setPredicate('country', values.country);
        }
        else
            removePredicate('country');
        
        
        clearProjectRegistry();
        loadProjects();
    }

    const loadStorePredicate = () => {
        let storePred = getPredicate();
        console.log(storePred);

        //Start form with Status Active, and Has Chargeable.
        if ((storePred.get("statusActive") == null) && (storePred.get("statusInactive") == null) && (storePred.get("statusClosed") == null) && (storePred.get("chargeableYes") == null) && (storePred.get("chargeableNo") == null) && (storePred.get("nonchargeableYes") == null) && (storePred.get("nonchargeableNo") == null)) {
            setPredicate("statusActive",true);
            setPredicate("chargeableYes",true);
        }

        let projCode: string | undefined = storePred.get("projectCode")?.toString();
        let projName: string | undefined = storePred.get("projectName")?.toString();
        let projManager: string | undefined = storePred.get("projectManager")?.toString();
        let projClient: string | undefined = storePred.get("client")?.toString();
        let projLot: string | undefined = storePred.get("lot")?.toString();
        let projBlock: string | undefined = storePred.get("block")?.toString();
        let projCountry: string | undefined = storePred.get("country")?.toString();
        let projAddress: string | undefined = storePred.get("street")?.toString(); 
        let projCity: string | undefined = storePred.get("city")?.toString();
        let projState: string | undefined = storePred.get("state")?.toString();
        let projCounty: string | undefined = storePred.get("county")?.toString();
        let projStatusActive = (storePred.get("statusActive")?.toString() === 'true');
        let projStatusInactive = (storePred.get("statusInactive")?.toString() === 'true');
        let projStatusClosed = (storePred.get("statusClosed")?.toString() === 'true');
        let projNonChargeableYes = (storePred.get("nonchargeableYes")?.toString() === 'true');
        let projNonChargeableNo = (storePred.get("nonchargeableNo")?.toString() === 'true');
        let projChargeableYes = (storePred.get("chargeableYes")?.toString() === 'true');
        let projChargeableNo = (storePred.get("chargeableNo")?.toString() === 'true');

        let searchVals = new ProjectSearchFormValues();
        if (projCode !== undefined) searchVals.projectCode = projCode;
        if (projName !== undefined) searchVals.projectName = projName;
        if (projManager !== undefined) searchVals.projectManager = projManager;
        if (projClient !== undefined) searchVals.client = projClient;
        if (projLot !== undefined) searchVals.lot = projLot;
        if (projBlock !== undefined) searchVals.block = projBlock;
        if (projCountry !== undefined) searchVals.country = projCountry;
        if (projAddress !== undefined) searchVals.street = projAddress;
        if (projCity !== undefined) searchVals.city = projCity;
        if (projCounty !== undefined) searchVals.county = projCounty;
        if (projState !== undefined) searchVals.state = projState;
        if (projChargeableYes !== undefined) searchVals.chargeableYes = projChargeableYes;
        if (projChargeableNo !== undefined) searchVals.chargeableNo = projChargeableNo;
        if (projStatusActive !== undefined) searchVals.statusActive = projStatusActive;
        if (projStatusClosed !== undefined) searchVals.statusClosed = projStatusClosed;
        if (projStatusInactive !== undefined) searchVals.statusInactive = projStatusInactive;
        if (projNonChargeableYes !== undefined) searchVals.nonchargeableYes = projNonChargeableYes;
        if (projNonChargeableNo !== undefined) searchVals.nonchargeableNo = projNonChargeableNo;

        setProjectSearch(searchVals);
    }

    

    useEffect(() => {
        loadStorePredicate();
    }, []);

    

    return (
        <>
            
            <Grid className='noPrint'>
                <Grid.Column width={16}>
                    <Segment clearing>
                        <Formik
                            enableReinitialize={true}
                            onSubmit={handleFormSubmit}
                            initialValues={projectSearch}
                        >
                            {({submitForm, values, resetForm}) => (
                                <Form className='ui form'>
                                    <Form.Group widths='equal' className='container'>
                                        <Form.Field className='fieldDiv'>
                                        <TextInputFormik name='projectCode' placeholder='Project Code'/>
                                        </Form.Field>
                                        <Form.Field className='fieldDiv'>
                                        <TextInputFormik name='projectName' placeholder='Project Name' />
                                        </Form.Field>
                                        <Form.Field className='fieldDiv'>
                                        <TextInputFormik
                                            name="projectManager"
                                            placeholder="Project Manager"
                                        />
                                        </Form.Field>
                                    </Form.Group>
                                    {moreVisible && 
                                    <>
                                        <Form.Group widths='equal'>
                                        <Form.Field className='fieldDiv'>
                                        <TextInputFormik name='client' placeholder='Client Name'/>
                                        </Form.Field>
                                        <Form.Field className='fieldDiv'>
                                        <TextInputFormik name='lot' placeholder='Lot'/>
                                        </Form.Field>
                                        <Form.Field className='fieldDiv'>
                                        <TextInputFormik name='block' placeholder='Block'/>
                                        </Form.Field>
                                        <Form.Field className='fieldDiv'>
                                        <TextInputFormik name='country' placeholder='Country'/>
                                        </Form.Field>
                                        </Form.Group>
                                        <Form.Group widths='equal'>
                                            <Form.Field className='fieldDiv'>
                                            <TextInputFormik name='street' placeholder='Address'/>
                                            </Form.Field>
                                            <Form.Field className='fieldDiv'>
                                            <TextInputFormik name='city' placeholder='City'/>
                                            </Form.Field>
                                            <Form.Field className='fieldDiv'>
                                                <SelectInputFormik
                                                    name="state"
                                                    placeholder="State"
                                                    options={states.map(s => ({
                                                        key: s.id,
                                                        value: s.stateAbbrev,
                                                        text: s.stateName
                                                    }))}
                                                />
                                            </Form.Field>
                                            <Form.Field className='fieldDiv'>
                                            <TextInputFormik name='county' placeholder='County'/>
                                            </Form.Field>
                                        </Form.Group>
                                        <Form.Group widths='equal'>
                                        
                                        {/* Status */}
                                        <div className='separatorDiv'>
                                            <div>Status:</div>
                                            <div><Field className='checkbox' type='checkbox' name='statusActive'/> Active</div>
                                            
                                            <div><Field className='checkbox' type='checkbox' name='statusInactive'/> Inactive</div>
                                            <div><Field className='checkbox' type='checkbox' name='statusClosed'/> Closed</div>
                                        </div>       
                                        {/* Has Chargeable */}
                                        <div className='separatorDiv'>
                                            Has Chargeable:
                                            <div><Field className='checkbox' type='checkbox' name='chargeableYes'/> Yes</div>
                                            <div><Field className='checkbox' type='checkbox' name='chargeableNo'/> No</div>
                                        </div>
                                        {/* Has Non-Chargeable */}

                                        <div className='separatorDiv'>
                                            Has Non-chargeable:
                                            <div><Field className='checkbox' type='checkbox' name='nonchargeableYes'/> Yes</div>
                                            <div><Field className='checkbox' type='checkbox' name='nonchargeableNo'/> No</div>
                                        </div>
                                        </Form.Group>
                                    </>
                                    }
                                    <Grid>
                                        <Grid.Column textAlign="center">
                                            
                                            <Button positive content="Search" type='button' onClick={submitForm}/>
                                                {!moreVisible && <Button color="orange" type="button" content="Show More" onClick={() => setMoreVisible(true)} />}
                                                {moreVisible && <Button color="orange" type="button" content="Hide More" onClick={() => setMoreVisible(false)} />}
                                            <Button color="red" type="button" content="Reset" onClick={() => {
                                                    clearPredicate();
                                                    clearProjectRegistry();
                                                    resetForm();
                                                    loadProjects();
                                                }
                                                } />
                                           
                                        </Grid.Column>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Segment>
                </Grid.Column>
            </Grid>
        </>
    )
}

export default observer(ProjectSearch);