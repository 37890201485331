import { fromUnixTime } from "date-fns";
import React from "react";
import {Link} from "react-router-dom";
import {Button, Icon, Item, Segment} from "semantic-ui-react";
import {IProjectSearch} from "../../../app/models/projectSearch";
import {observer} from 'mobx-react-lite';
import {format} from 'date-fns';
import ConflictDashboard from "./ConflictDashboard";
import { InvalidatedProjectKind } from "typescript";

const ProjectListItem: React.FC<{ project: IProjectSearch}> = ({project}) => {

    return (
        <Segment.Group>
            <Segment>
                <Item.Group>
                    <Item>
                        <Item.Content>
                            <Item.Header>Project: {project.projectName}</Item.Header>
                            <Item.Meta>Manager: <mark className='magenta'>{project.projectManager}</mark></Item.Meta>
                            <Item.Description>Code: {project.projectCode}</Item.Description>
                            
                        </Item.Content>
                    </Item>
                </Item.Group>
            </Segment>
            <Segment secondary>
                <Icon name="user" color="blue"/> Client: <mark className="magenta">{project.clientName}</mark><br />
                <Item.Description><Icon name="file alternate outline" color="green"/>Status: 
                {
                    project.projectStatus == '0' ? <> Active</> : project.projectStatus == '1' ? <> Inactive</> : project.projectStatus == '4' ? <> Closed</> : '' 
                }     
                </Item.Description>
            </Segment>
            <Segment>
                {
                    project.description ? 
                    <>
                        <div id='conflictDesc'><b>Description:</b><br /> {project.description}</div>     
                    </> : ''
                }
            </Segment>
                    
            <Segment clearing>
                <Button
                as={Link}
                to={`/project/${project.projectCode}`}
                floated="right"
                content="View"
                color="blue"
                />
            </Segment>
        </Segment.Group>
    )
};

export default observer(ProjectListItem);