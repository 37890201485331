import React, { useContext, useEffect, useState } from 'react'
import { observer } from "mobx-react-lite";
import InfiniteScroll from 'react-infinite-scroller';

import LoadingComponent from "../../../app/layout/LoadingComponent";
import { RootStoreContext } from "../../../app/stores/rootStore";
import PageTitle from "../../../app/common/form/PageTitle";
import ConflictList from './ConflictList';
import ConflictSearch from './ConflictSearch';

const ConflictDashboard: React.FC = () => {

  const rootStore = useContext(RootStoreContext);
  
  const { loadConflicts, loadingInitial, setPage, page, totalPages, } = rootStore.conflictStore;

  const [loadingNext, setLoadingNext] = useState(false);

  const handleGetNext = () => {
    setLoadingNext(true);
    setPage(page + 1);
    loadConflicts().then(() => setLoadingNext(false));
    console.log('page: ' + page);
    console.log('totalPages: ' + totalPages);
  }

  useEffect(() => {
    loadConflicts();
    console.log('page: ' + page);
    console.log('totalPages: ' + totalPages);
  }, [loadConflicts]);

  if (loadingInitial && page === 0)
    return <LoadingComponent content="Loading reports..." />;
  else



  return (
    <>
      <PageTitle title="All Conflict Checks" />

      <ConflictSearch />

      <InfiniteScroll 
        pageStart={0} 
        loadMore={handleGetNext} 
        hasMore={!loadingNext && page + 1 < totalPages} 
        initialLoad={false}   
      >
        <ConflictList />
      </InfiniteScroll>

    </>
  )
}

export default observer(ConflictDashboard);
