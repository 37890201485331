export interface IMunicipality {
    
    id: number,
    code: string;
    name: string;
}

export class IMunicipalityFormValues implements IMunicipality {
    id: number = 0; //id will not be used in the addition of a new municipality, as that is ticked up in the db for each one added.
    code: string = '';
    name: string = '';

    constructor(init?: IMunicipalityFormValues) {
        Object.assign(this, init);
    }
}