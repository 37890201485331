import { action, computed, observable, runInAction } from "mobx";
import { toast } from "react-toastify";

import { history } from "../..";
import agent from "../api/agent";
import { IDigitalSignature } from "../models/digitalSignature";
import { IDSProject } from "../models/dsProject";
import { ITask } from "../models/task";
import { RootStore } from "./rootStore";

export default class DigitalSignatureStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    @observable projectRegistry = new Map<string, IDSProject>();
    @observable taskRegistry = new Map<string, ITask>();

    @observable loadingInitial = false;
    @observable loadingValidation = false;
    @observable submitting = false;

    /*************************************
     * get registries
     *************************************/
    @computed get projectList() {
        return Array.from(this.projectRegistry.values()).sort(
            (a, b) => a.title.localeCompare(b.title)
        );
    }

    @computed get taskList() {
        return Array.from(this.taskRegistry.values()).sort(
            (a, b) => a.taskCode.localeCompare(b.taskCode)
        );
    }

    /*************************************
    * API calls - populate registries
    *************************************/
    @action searchProjects = async (searchVal: string) => {
        try {
            
            const projects = await agent.DigitalSignature.searchProjects(searchVal);
            
            runInAction('loading projects', () => {
                this.projectRegistry.clear();
                projects.forEach((project) => {
                    this.projectRegistry.set(project.title, project);
                });
            });
        } catch (error) {
            runInAction("load projects error", () => {
                this.loadingInitial = false;
            });
            console.log(error);
        }
    };
    
    @action loadTasks = async (project: string) => {
        try {
            const tasks = await agent.DigitalSignature.listTasks(project);
            runInAction('loading tasks', () => {
                this.taskRegistry.clear();
                tasks.forEach((task) => {
                    this.taskRegistry.set(task.taskCode, task);
                });
            });
        } catch (error) {
            runInAction("load tasks error", () => {
                this.loadingInitial = false;
            });
            console.log(error);
        }
    };
    
    /*************************************
     * submit form
     *************************************/
    @action createDigitalSignatureForm = async (digitalSignatureForm: IDigitalSignature) => {
        this.submitting = true;
        try {
            await agent.DigitalSignature.create(digitalSignatureForm);
            
            runInAction("create digital signature", () => {
                this.submitting = false;
            });
            history.push('/');
            toast.success("Request submitted.")
        } catch (error) {
            runInAction("create digital signature error", () => {
                this.submitting = false;
            });
            
            toast.error("Problem submitting data.");
            console.log(error);
        }
    };

    // @action loadProjects = async () => {
    //     try {
    //         const projects = await agent.DigitalSignature.listProjects();
    //         runInAction('loading projects', () => {
    //             this.projectRegistry.clear();
    //             projects.forEach((project) => {
    //                 this.projectRegistry.set(project.title, project);
    //             });
    //         });
    //     } catch (error) {
    //         runInAction("load projects error", () => {
    //             this.loadingInitial = false;
    //         });
    //         console.log(error);
    //     }
    // };
    
    // @action projectExists = async (project: string) => {
    //     this.loadingValidation = true;
        
    //     const response = await agent.DigitalSignature.projectExists(project);
        
    //     runInAction("loading project validator", () => {
    //         this.loadingValidation = false;
    //     });

    //     return response;
    // };
}