
export interface IInitiationSearch {


    projectManager: string;
    admin: string;
    createdBy: string;

    //projectName: string;
    //description: string;

    //company: string;
    //companyContact: string;
    //location: string;
    //state: number;

    //clientType: number;
    //confidential: number;
    //appointed: number;
    //hasRailroadComponent: number;
}

export class InitiationSearchFormValues implements IInitiationSearch {

    projectManager: string = '';
    admin: string = '';
    createdBy: string = '';

    //projectName: string = '';
    //company: string = '';

    //confidential: number = -1;
    //appointed: number = -1;
    //hasRailroadComponent: number = -1;
    //clientType: number = -1;

    //companyContact: string = '';
    //location: string = '';
    //state: number = 0;
    //description: string = '';

    constructor(init?: IInitiationSearch) {

        Object.assign(this, init);
    }
}
